import {
    Comments, CompanyDirectoryResponse, CopyDayObjectUtil, CopyScheduleObjectUtil, CopyToDateUtil,
    DayStats,
    EmployeeListResponse,
    FullMonthResponse,
    FullWeekResponse, JobBoardResponse, JobBoardUtil, LocationIdsUtil,
    Priority, ScheduledDayObjectResponse,
    ScheduledWeekObjectResponse,
    ScheduleObject,
    ScheduleObjectUtil, ScheduleReportLineChartData,
    ScheduleReportMonthlyHourChartData,
    ScheduleReportMonthlyWagesChartData,
    ScheduleReportYearlyHourChartData, ScheduleReportYearlyLineChart,
    ScheduleReportYearlyWagesChartData,
    SubTaskCheckbox,
    TaskCategories, TaskCategoriesPage, taskCategoriesPageInit,
    TaskInfo, TaskList, TaskLocations, TaskLocationsPage, taskLocationsPageInit,
    TaskResponse,
    TaskTemplate, ScheduleList, MasterCopyUtil, ExtraMonthResponse, CopyDayObjectUtilResponse
} from "../../types/task/TaskTypes";
import {createSlice} from "@reduxjs/toolkit";
import {AppThunkDefault, taskInfoActions} from "../storeIndex";
import {areDatesEqual, getLastDayInCurrentWeek} from "../../service/generic/DateHelper";
import {UserInfo} from "../../types/account/AccountTypes";
import {ScheduleLineChartData, SingleDayScheduleReportHolder} from "../../types/charts/chartTypes";
import {DateObject} from "react-multi-date-picker";

export interface TaskInfoApiResponse {
    selectedDate: string,
    rangeStartDate: string,
    rangeEndDate: string,
    selectedCalendarDate: string,
    taskInfo: TaskInfo,
    monthlyTasks?: TaskInfo[],
    weeklyTasks?: TaskInfo[],
    daysTasks?: TaskInfo[],
    daysScheduledEmployees?: ScheduleObject[],
    singleDayScheduleReportHolder?: SingleDayScheduleReportHolder,
    fullMonthResponse: FullMonthResponse,
    copyDayObjectUtil:CopyDayObjectUtil
    extraMonthResponse: ExtraMonthResponse,
    fullWeekInfoState:FullWeekResponse,
    taskTemplates: TaskTemplate[],
    allTaskCategoriesForAccount: TaskCategories[],
    allTaskLocationsForAccount: TaskLocations[],
    taskCategories: TaskCategoriesPage,
    taskLocations: TaskLocationsPage,
    tasksToChangeLocationList:TaskInfo[],
    locationIdToDelete: string,
    taskPriorityFilter:string,
    showSideMenu: boolean,
    isModalOpen: boolean,
    availableWorkersToAssignOnSelectedDay: UserInfo[],
}

export const fullMonthResponseInit: FullMonthResponse = {
    monthList: [],
    nameOfMonthDisplay: '',
    yearDisplay:''
}

export const copyDayObjectUtilInit: CopyDayObjectUtil = {
    employeeIds: [],
    employeeNames:[],
    taskNames:[],
    taskIds:[],
    numberOfTasks:0,
    fromDate: '',
    toDate: '',
    scheduleAndAssignWorkers:false,
    assignedStartTime: '',
    assignedEndTime: ''
}

export const extraMonthResponseInit: ExtraMonthResponse = {
    extraMonthList: []
}

export const singleDayScheduleReportHolderInit: SingleDayScheduleReportHolder = {
    dayReport: [],
    date: '',
}

export const fullWeekResponseInit: FullWeekResponse = {
    weekList: []
}

export const scheduleObjectWeekResponseInit: ScheduledWeekObjectResponse = {
    scheduledDayObjectList: [],
    wereUsersToBeScheduledAlreadyScheduled:false
}

export const scheduleObjectDayResponseInit: ScheduledDayObjectResponse = {
    scheduledObjectsList: [],
    weekNameDisplay:'',
    date:''
}

export const jobBoardResponseInit: JobBoardResponse = {
    jobBoardUtilList: [],
    displayDate:'',
    displayDateName:'',
    displayYear:'',
    totalTasksForTheDay:0,
    totalCompletedTasksForTheDay:0,
    totalUncompletedTasksForTheDay:0,
    totalEmployeesScheduled:0
}

export const companyDirectoryResponseInit: CompanyDirectoryResponse = {
    companyDirectoryList: [],
    totalEmployees:0
}

export const employeeListResponseInit: EmployeeListResponse = {
    employeeList: []
}

export const subTaskCheckboxInit: SubTaskCheckbox = {
    label:'',
    completed:false,
    touched: false
}

export const taskInfoInit: TaskInfo = {
    assignedTaskId: '',
    taskName: '',
    accessCode: '',
    orgName:'',
    completedMonthYear:'',
    categoryId: '',
    categoryName: '',
    locationIds:[],
    locationNames:[],
    estCompleteHours: '',
    assignedUserIds: [],
    assignedUserDisplayNames: [],
    scheduledDate: Date(),
    deadlineTimeString:'',
    taskNotes: '',
    completed: false,
    hoursSpentCompleting: '',
    priority: Priority.LOW,
    subTaskLabels: [subTaskCheckboxInit],
    saveNewTaskTemplate: false,
    overwriteSavedTaskTemplate:false,
    templateId:'',
}

export const scheduleInfoInit: ScheduleObject = {
    employeeId: '',
    scheduleDate: Date(),
    scheduleEndDate: '',
    employeeName: '',
    assignedStartTime: '',
    assignedEndTime: '',
    displayStartTime:'',
    displayEndTime:'',
    accessCode:''
}

export const scheduleReportMonthlyHourChartDataInit: ScheduleReportMonthlyHourChartData = {
    employeeName:'',
    hoursWorked:0
}

export const scheduleReportMonthlyHourChartDataHolderInit: ScheduleReportMonthlyHourChartData = {
    employeeName:'',
    hoursWorked:0
}

export const scheduleReportMonthlyWagesChartDataInit: ScheduleReportMonthlyWagesChartData = {
    employeeName:'',
    wagesPaid:0
}

export const scheduleReportMonthlyWagesChartDataHolderInit: ScheduleReportMonthlyWagesChartData = {
    employeeName:'',
    wagesPaid:0
}

export const scheduleReportYearlyHourChartDataInit: ScheduleReportYearlyHourChartData = {
    employeeName:'',
    hoursWorked:0
}

export const scheduleReportYearlyHourChartDataHolderInit: ScheduleReportYearlyHourChartData = {
    employeeName:'',
    hoursWorked:0
}

export const scheduleReportYearlyWagesChartDataInit: ScheduleReportYearlyWagesChartData = {
    employeeName:'',
    wagesPaid:0
}

export const scheduleReportYearlyWagesChartDataHolderInit: ScheduleReportYearlyWagesChartData = {
    employeeName:'',
    wagesPaid:0
}

export const CommentsInfoInit: Comments = {
    commentId: '',
    authorId: '',
    authorName: '',
    commentDate: new Date(),
    commentString: '',
    imagePath:null,
    multipartFile: null,
}

export const LocationIdsUtilInfoInit: LocationIdsUtil = {
    locationIds: [],
    locationNames: [],
}

export const copyToDateUtilInit: CopyToDateUtil = {
    dates: []
}

export const ScheduleUtilInfoInit: ScheduleObjectUtil = {
    employeeIds: [],
    employeeNames: [],
    scheduleDate:'',
    scheduleEndDate: '',
    assignedStartTime: '',
    assignedEndTime: '',
}

export const copyDayUtilInfoInit: CopyDayObjectUtil = {
    employeeIds: [],
    employeeNames: [],
    taskIds:[],
    numberOfTasks:0,
    taskNames:[],
    fromDate:'',
    toDate: '',
    scheduleAndAssignWorkers:false,
    assignedStartTime: '',
    assignedEndTime: ''
}

export const copyScheduleUtilInfoInit: CopyScheduleObjectUtil = {
    employeeIds: [],
    employeeNames: [],
    toDates: [],
    fromDate: '',
    wholeWeekCheckBox:false,
    selectedDate:''
}

export const masterCopyUtilInit: MasterCopyUtil = {
    copyTasksCheckBox:true,
    copyScheduledWorkers:true,
    fromDates:[],
    toDates:[],
    accessCodes:[]
}

export const todaysTasksInit: TaskList = {
    taskList: []
}

export const scheduleListInit: ScheduleList = {
    scheduledList: []
}

export const taskInfoState: TaskInfoApiResponse = {
    selectedDate: new Date().toISOString(),
    rangeStartDate: new Date().toISOString(),
    rangeEndDate: new Date().toISOString(),
    selectedCalendarDate: new Date().toISOString(),
    taskInfo: taskInfoInit,
    monthlyTasks: [],
    weeklyTasks: [],
    daysTasks: [],
    daysScheduledEmployees: [],
    singleDayScheduleReportHolder: singleDayScheduleReportHolderInit,
    fullMonthResponse: fullMonthResponseInit,
    copyDayObjectUtil: copyDayObjectUtilInit,
    extraMonthResponse: extraMonthResponseInit,
    fullWeekInfoState: fullWeekResponseInit,
    taskTemplates: [],
    allTaskCategoriesForAccount: [],
    allTaskLocationsForAccount: [],
    taskCategories: taskCategoriesPageInit,
    taskLocations: taskLocationsPageInit,
    locationIdToDelete: '',
    taskPriorityFilter:'',
    tasksToChangeLocationList:[],
    showSideMenu: true,
    isModalOpen:false,
    availableWorkersToAssignOnSelectedDay: []
}

export const taskInfoSlice = createSlice({
    name: 'taskInfo',
    initialState: taskInfoState,
    reducers: {
        setTaskInfo(state, action) {
            state.taskInfo = action.payload.taskInfo;
        },
        setSelectedDate(state, action) {
            state.selectedDate = action.payload.selectedDate;
        },
        setRangeStartDate(state, action) {
            state.rangeStartDate = action.payload.rangeStartDate;
        },
        setRangeEndDate(state, action) {
            state.rangeEndDate = action.payload.rangeEndDate;
        },
        setSelectedCalendarDate(state, action){
            state.selectedCalendarDate = action.payload.value;
        },
        setMonthlyTasks(state, action) {
            state.monthlyTasks = action.payload.monthlyTasks;
            state.weeklyTasks = state.monthlyTasks!.filter(task =>
                new Date(task.scheduledDate!) < getLastDayInCurrentWeek(state.selectedDate!));
            state.daysTasks = state.monthlyTasks!
                .filter(task => areDatesEqual(task.scheduledDate!, state.selectedDate!));
        },
        setFullMonthResponse(state, action) {
            state.fullMonthResponse = action.payload.fullMonthResponse;
        },
        setCopyDayObjectUtil(state, action) {
            state.copyDayObjectUtil = action.payload.copyDayObjectUtil;
        },
        setExtraMonthResponse(state, action) {
            state.extraMonthResponse = action.payload.extraMonthResponse;
        },
        setSingleDayScheduleReportHolder(state, action){
            state.singleDayScheduleReportHolder = action.payload.singleDayScheduleReportHolder;
        },
        setDaysTasks(state, action){
            state.daysTasks = action.payload.daysTasks;
        },
        setDaysScheduledEmployees(state, action){
            state.daysScheduledEmployees = action.payload.daysScheduledEmployees;
        },
        setFullWeekInfoState(state, action){
            state.fullWeekInfoState = action.payload.fullWeekInfoState;
        },
        setSavedTemplates(state, action) {
            state.taskTemplates = action.payload.taskTemplates;
        },
        setTaskCategoriesPage(state, action) {
            state.taskCategories = action.payload.categoriesPage;
        },
        setAllTaskCategoriesForAccount(state, action) {
            state.allTaskCategoriesForAccount = action.payload.value;
        },
        setTaskLocationsPage(state, action) {
            state.taskLocations = action.payload.locationsPage;
        },
        setLocationIdToDelete(state, action) {
            state.locationIdToDelete = action.payload.value;
        },
        setTaskPriorityFilter(state, action) {
            state.taskPriorityFilter = action.payload.value;
        },
        setTasksToChangeLocationList(state, action) {
            state.tasksToChangeLocationList = action.payload.value;
        },
        setAllTaskLocationsForAccount(state, action) {
            state.allTaskLocationsForAccount = action.payload.value;
        },
        setShowSideMenu(state, action) {
            console.log("setting side nav new")
            console.log(action.payload.show)
            state.showSideMenu = action.payload.show;
        },
        setIsModalOpen(state, action) {
            state.isModalOpen = action.payload.value;
        },
        setAvailableWorkersToAssignOnSelectedDay(state, action) {
            state.availableWorkersToAssignOnSelectedDay = action.payload.workers;
        },
        resetToInit(state) {
            state = taskInfoState;
        }
    }
});

/*
export const fetchTaskInfo = (taskObject: TaskInfo):
    AppThunk => async (dispatch) => {
    dispatch(taskInfoActions.updateSuccess({userInfo: taskObject}));
}*/
