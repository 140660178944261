import React, {Fragment, ReactElement, ReactFragment, useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {
  Alert,
  Checkbox,
  FormControlLabel,
  Paper, Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow, useMediaQuery
} from "@mui/material";
import EnhancedTableToolbar from "./EnhancedTableToolbar";
import EnhancedTableHead, {Order} from "./EnhancedTableHead";
import {getComparator, stableSort} from "../../../service/generic/tableService";
import {Role, UserInfo} from "../../../types/account/AccountTypes";

type Props = {
  id?: string,
  rows: TableOptions[],
  headCells: any[],
  TableCellsFunc: (props: TableCellFuncProps) => ReactElement,
  selectedRows: string[],
  setSelectedRows: (selected: string[]) => void,
  rowIdsList: string[],
  tableName?: string,
  determineRowHidden?: (rowId: string, filterText:string) => boolean
  noSelection?:boolean,
  hideFilter?:boolean,
  dense?:boolean,
  pageSize?: number,
  customOnClickFunction?: (val:number) => void,
  maskFieldsTooltipText?:string,
  user?:UserInfo
}

export interface TableOptions {
  id: string;
}

export interface TableCellFuncProps {
  row: TableOptions,
  labelId: string,
  showMaskedField?: boolean,
  user?:UserInfo,
}

const CustomTable = (props: Props) => {
  const isWindowSmall = useMediaQuery('(max-width:415px)');

  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof TableOptions>("id");
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(props.dense);
  const [rowsPerPage, setRowsPerPage] = React.useState(props.pageSize ? props.pageSize : 10);
  const [filterText, setFilterText] = useState('');
  const [matchingFilterRowIds, setMatchingFilterRowIds] = useState([]);
  const [searchFilterText, setSearchFilterText] = useState('');
  const [showMasked, setShowMasked] = useState(false);

  const selected = props.selectedRows;
  const setSelected = props.setSelectedRows;

  /*useEffect(() => {
    props.rowIdsList.map((id, iter) => props.rows[iter].id = id);
  }, [props.rowIdsList]);*/

  const handleRequestSort = (
      event: React.MouseEvent<unknown>,
      property: keyof TableOptions,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = props.rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleCustomFunctionClick = (index:number) => {
    if (props.customOnClickFunction) {
      console.log(index)
      props.customOnClickFunction(index);
    }
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const determineRowHidden = (id: string) => {
    return props.determineRowHidden ? props.determineRowHidden(id, searchFilterText) : false;
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.rows.length) : 0;

  return (
      <div style={{maxWidth: isWindowSmall ? '99%' : '100%', marginLeft: isWindowSmall ? "1%" : "0"}}>
        <Paper sx={{ width: '100%', mb: 2 }} id={props.id}>
          { props.rows.length == 0 &&
            <Alert severity="warning">There are no entries to show yet!</Alert>
          }
          <EnhancedTableToolbar
              numSelected={selected.length}
              tableName={props.tableName}
              searchFilterText={searchFilterText}
              setSearchFilterString={setSearchFilterText}
              hideFilter={props.hideFilter}
              setShowMasked={setShowMasked}
              showMasked={showMasked}
              maskFieldsTooltipText={props.maskFieldsTooltipText}
          />
          <TableContainer>
            <Table
                sx={{ minWidth: 750, backgroundColor:"white" }}
                aria-labelledby="tableTitle"
                size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                  headCells={props.headCells}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={props.rows.length}
                  noSelection={props.noSelection}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.slice().sort(getComparator(order, orderBy)) */}
                {stableSort(props.rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                          <TableRow
                              style={{display: determineRowHidden(row.id) ? 'none' : 'table-row'}}
                              hover
                              onClick={(event) => props.customOnClickFunction ? handleCustomFunctionClick(index) : handleClick(event, row.id)}
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row.id}
                              selected={isItemSelected}
                          >
                            { props.noSelection !== true &&
                              <TableCell padding="checkbox">
                                <Checkbox
                                    color="primary"
                                    checked={isItemSelected}
                                    inputProps={{
                                      'aria-labelledby': labelId,
                                    }}
                                />
                              </TableCell>
                            }

                            <props.TableCellsFunc row={row} labelId={labelId} showMaskedField={showMasked} user={props.user}/>
                            {/*<TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                            >
                              {row.name}
                            </TableCell>
                            <TableCell align="right">{row.calories}</TableCell>
                            <TableCell align="right">{row.fat}</TableCell>
                            <TableCell align="right">{row.carbs}</TableCell>
                            <TableCell align="right">{row.protein}</TableCell>*/}
                          </TableRow>
                      );
                    })}
                {emptyRows > 0 && (
                    <TableRow
                        style={{
                          height: (dense ? 33 : 53) * emptyRows,
                        }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
              sx={{backgroundColor:"white"}}
              rowsPerPageOptions={[5, 10, props.pageSize ? props.pageSize : 25]}
              component="div"
              count={props.rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        <FormControlLabel
            control={<Switch checked={dense} color={"success"} onChange={handleChangeDense} />}
            label="Dense padding"
        />
      </div>
  );
}

export default CustomTable;