import React, {Fragment, useEffect, useState} from "react";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Chip, CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  TextField
} from "@mui/material";
import {scheduleObjectWeekResponseInit, ScheduleUtilInfoInit} from "../../store/task/taskInfoSlice";
import {
  EmployeeListResponse,
  ScheduledDayObjectResponse,
  ScheduledWeekObjectResponse,
  ScheduleInfoValid,
  scheduleInfoValidInit
} from "../../types/task/TaskTypes";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import {Role, UserInfo} from "../../types/account/AccountTypes";
import {MenuMultiSelectProps} from "../../types/generic/MaterialUISelect";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {DatePicker, LoadingButton, LocalizationProvider, TimePicker} from "@mui/lab";
import {useSelector} from "react-redux";
import {RootState, scheduleActions, taskInfoActions} from "../../store/storeIndex";
import {taskManagementService} from "../../service/taskManagementService";
import {formatDate, formatDateTime} from "../../service/generic/DateHelper";
import {determineIfTokenError} from "../../store/account/authSlice";
import {useAppThunkDispatch} from "../../store/storeHooks";
import {start} from "repl";
import LoadingSpinnerContainer from "../general/LoadingSpinnerContainer";
import {DARK_NAVY, LIME_GREEN} from "../../service/generic/ColorScheme";
import {grey} from "@mui/material/colors";

type Props = {
  title: string,
  isOpen: boolean,
  setIsOpen: (open: false) => void,
  employeeList: EmployeeListResponse,
  selectedDate: string,
  scheduleInfoValid: ScheduleInfoValid,
  scheduledObjects: ScheduledDayObjectResponse,
  setShowDependantParentLoading?: (value: boolean) => void,
  accessCodeToUse?:string,
  taskDate?:string | Date | null,
}

const ScheduleDialog: React.FC<Props> = (props) => {
  let dispatch = useAppThunkDispatch();

  const availableWorkersToAssignOnSelectedDay = useSelector((state: RootState) => state.taskInfo.availableWorkersToAssignOnSelectedDay);
  const selectedDate = useSelector((state: RootState) => state.taskInfo.selectedDate);
  const orgMembers = useSelector((state: RootState) => state.account.orgMembers);

  const scheduleDayObjectResponseGlobal = useSelector((state: RootState) => state.schedule.scheduleDayObjectResponse);
  const [scheduleDayObjectResponseLocal, setScheduleDayObjectResponseLocal] = useState(scheduleDayObjectResponseGlobal);
  const [scheduleObjectInfoState, setScheduleObjectInfoState] = useState(ScheduleUtilInfoInit);
  const [scheduleInfoStateValid, setScheduleInfoStateValid] = useState(scheduleInfoValidInit);
  const [scheduledWeekObjectResponse, setScheduledWeekObjectResponse] = useState<ScheduledWeekObjectResponse>(scheduleObjectWeekResponseInit);
  const userInfo = useSelector((state: RootState) => state.userInfo);
  const orgInfo = useSelector((state: RootState) => state.orgInfo);
  const [showLoading, setShowLoading] = React.useState(false);
  const [formTouched, setFormTouched] = React.useState(false);
  const [assignmentOptions, setAssignmentOptions] = React.useState([...orgMembers]);
  const [scheduleCardOpen, setScheduleCardOpen] = React.useState(false);
  const [selectedScheduleNames, setSelectedScheduleNames] = useState<string[]>([]);
  const [scheduleTimesOverlappingOnDay, setScheduleTimesOverlappingOnDay] = React.useState<string[]>([]);

  useEffect(() => {
    document.title = "Schedule Employee Dialog";
  }, []);

  /*useEffect(() => {
    console.log("modal is open use effect")
    dispatch(taskInfoActions.setIsModalOpen({value: props.isOpen}));
  }, [props.isOpen])*/

  useEffect(() => {
    if (props.isOpen) {
      let initState = {...ScheduleUtilInfoInit};
      if (props.taskDate) {
        //const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        //const localDate = convertToLocalTime(Date.parse(props.taskDate.toLocaleString()), timezone);
        let date = new Date(props.taskDate);
        let schedDate : Date = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
        initState.scheduleDate = schedDate;
        initState.scheduleEndDate = schedDate;
      } else {
        initState.scheduleDate = selectedDate.toString();
        initState.scheduleEndDate = selectedDate.toString();
      }
      setScheduleObjectInfoState(initState);
      setScheduleInfoStateValid(scheduleInfoValidInit);

      let members: UserInfo[] = [];
      const indexOfObject = members.findIndex((UserInfo) => {
        return UserInfo.role === "ORG_CONTRACT";
      });
      if (indexOfObject !== -1) {
        members.splice(indexOfObject, 1);
      }
      setPeopleToSchedule(members);

      setFormTouched(false);

    }
  }, [props.isOpen])

  useEffect(() => {
    setScheduleDayObjectResponseLocal(scheduleDayObjectResponseGlobal)
  }, [scheduleDayObjectResponseGlobal]);

  useEffect(() => {
    console.log(scheduleObjectInfoState.assignedStartTime);
    console.log(scheduleObjectInfoState.assignedEndTime);
  }, [scheduleObjectInfoState.assignedStartTime, scheduleObjectInfoState.assignedEndTime])

/*  useEffect(() => {
    setAssigneesOptions(orgMembers);
  }, [orgMembers])*/

  const setPeopleToSchedule = (members:UserInfo[]) => {
    setAssignmentOptions(members)
  }

  const [value, setValue] = React.useState<Date | null>(
      new Date(),
  );

  const handleScheduleSubmit = () => {
    if (isFormValid() && showLoading == false) {
      if (props.setShowDependantParentLoading) {
        props.setShowDependantParentLoading(true);
      }
      setShowLoading(true);
      scheduleObjectInfoState.scheduleDate = formatDate(scheduleObjectInfoState.scheduleDate)!;
      scheduleObjectInfoState.scheduleEndDate = formatDate(scheduleObjectInfoState.scheduleEndDate)!;
      taskManagementService.scheduleEmployee(scheduleObjectInfoState, formatDate(selectedDate), props.accessCodeToUse ? new Array(props.accessCodeToUse) : orgInfo.allSelectedAccessCodes!)
          .then((resp: ScheduledWeekObjectResponse) => {
            dispatch(scheduleActions.setScheduledObjectResponse({value: resp}));
            let dayObjects = resp.scheduledDayObjectList.filter(day => day.date === selectedDate);
            if (dayObjects) {
              dispatch(scheduleActions.setScheduleDayObjectResponse({value:dayObjects[0]}));
            }
          }).catch(e => {
            dispatch(determineIfTokenError(e));
          }).finally(() => {
            setShowLoading(false);
            props.setIsOpen(false);
          });
    }
  }

  const getScheduledOjects = (date:Date) => {
    taskManagementService.getScheduledOjects(formatDate(date), orgInfo.allSelectedAccessCodes!)
        .then((resp: ScheduledDayObjectResponse) => {
          dispatch(scheduleActions.setScheduleDayObjectResponse({value:resp}));
          setScheduleDayObjectResponseLocal(resp);
        }).catch(e => {
      dispatch(determineIfTokenError(e));
    });
  }

  const onAssigneesChange = (event: SelectChangeEvent<typeof scheduleObjectInfoState.employeeIds>) => {
    setFormTouched(true);
    const {target: {value}} = event;
    let names = typeof value === 'string' ? value.split(',') : value;
    setSelectedScheduleNames(names);
    let newScheduleState = {...scheduleObjectInfoState};
    newScheduleState.employeeNames = names;

    let userIds = names
        .map(name => {
          return orgMembers.filter(member =>
              name.includes(member.firstName) && name.includes(member.lastName))[0];
        })
        .flatMap(member => member.userId);
    newScheduleState.employeeIds = userIds;
    setScheduleObjectInfoState(newScheduleState);

    let newScheduleValidState = {...scheduleInfoStateValid};
    newScheduleValidState.employeeSelected = userIds.length > 0;
    setScheduleInfoStateValid(newScheduleValidState);
    /*checkIfWorkerAlreadyScheduled(names, newScheduleState.assignedStartTime, newScheduleState.assignedEndTime);*/
  }

  const setScheduledDate = (date: Date | null) => {
    let newScheduleState = {...scheduleObjectInfoState};
    newScheduleState.scheduleDate = date;
    newScheduleState.scheduleEndDate = date;
    setScheduleObjectInfoState(newScheduleState);

    let valid = isDateValidInRange(date!, date!, date!);
    let newScheduleValidState = {...scheduleInfoStateValid};
    newScheduleValidState.scheduleDate = valid;
    newScheduleValidState.endDate = valid;
    setScheduleInfoStateValid(newScheduleValidState);

    if (false) {
      getScheduledOjects(date!);
    }
    /*checkIfWorkerAlreadyScheduled(selectedScheduleNames, newScheduleState.assignedStartTime, newScheduleState.assignedEndTime);*/
  }

  const setScheduledEndDate = (date: Date | null) => {
    let newScheduleState = {...scheduleObjectInfoState};
    newScheduleState.scheduleEndDate = date;
    setScheduleObjectInfoState(newScheduleState);

    let valid = isDateValidInRange(date, scheduleObjectInfoState.scheduleDate!, date);
    let newScheduleValidState = {...scheduleInfoStateValid};
    newScheduleValidState.endDate = valid;
    setScheduleInfoStateValid(newScheduleValidState);
  }

  const isDateValidInRange = (dateChanged: string | Date | null, startDate: string | Date | null, endDate: string | Date | null) => {
    try {
      let actualDate = new Date(dateChanged!);
      if (actualDate.getFullYear() < 2010 || actualDate.getFullYear() > 2040) {
        return false;
      }
    } catch (e) {
      return false;
    }
    return dateChanged !== null && (dateChanged.toString()).trim().length > 0 && new Date(startDate!) <= new Date(endDate!);
  }

  const isTimeValidInRange = (timeChanged: string | Date | null, startTime: string | Date | null, endTime: string | Date | null) => {
    try {
      let actualDate = new Date(timeChanged!);
      if (timeChanged!.toString().toLowerCase().includes('invalid')) {
        return false;
      }
    } catch (e) {
      return false;
    }
    let valid = timeChanged !== null && (timeChanged.toString()).trim().length > 0;
    if (valid && startTime && endTime && (startTime!.toString()).trim().length > 0 && (endTime!.toString()).trim().length > 0) {
      valid = new Date(startTime!) < new Date(endTime!);
    }
    return valid;
  }

  const cancelSchedule = () => {
    props.setIsOpen(false);
    let newSchedulesOverlapping:string[] = [];
    setScheduleTimesOverlappingOnDay(newSchedulesOverlapping);
  }

  const checkIfWorkerAlreadyScheduled = (names: string[], startTime:string, endTime:string) => {
    let newSchedulesOverlapping:string[] = [];
    setScheduleTimesOverlappingOnDay(newSchedulesOverlapping);
    if(scheduleDayObjectResponseLocal != undefined){
      for(let scheduleObject of scheduleDayObjectResponseLocal.scheduledObjectsList){
        for(let name of names){
          if(scheduleObject.employeeName === name){

            let startDateTime = new Date(startTime);
            let endDateTime = new Date(endTime);

            let orginalStartTime = new Date(scheduleObject.assignedStartTime);
            let orginalEndTime = new Date(scheduleObject.assignedEndTime);

            console.log(name + " is already working this day");
            console.log(startDateTime);
            console.log(endDateTime);
            console.log(orginalStartTime);
            console.log(orginalEndTime);
            console.log((startDateTime > orginalStartTime && startDateTime < orginalEndTime));
            console.log(orginalEndTime);

            if((startDateTime > orginalStartTime && startDateTime < orginalEndTime) ||
                (endDateTime > orginalStartTime && endDateTime < orginalEndTime)){
              console.log(name + " Time overLapping")
              newSchedulesOverlapping.push(name);
              setScheduleTimesOverlappingOnDay(newSchedulesOverlapping);
            }

          }
        }
      }
    }
  }

  const setScheduledStartTime = (time: Date | string | null) => {
    setFormTouched(true);
    let newScheduleState = {...scheduleObjectInfoState};
    let startTime = formatDateTime(time!.toString())!;
    newScheduleState.assignedStartTime = startTime;
    //newScheduleState.assignedEndTime = startTime;
    setScheduleObjectInfoState(newScheduleState);

    /*checkIfWorkerAlreadyScheduled(selectedScheduleNames, startTime, newScheduleState.assignedEndTime);*/

    if(time != null){
      let validStart = isTimeValidInRange(time, time, newScheduleState.assignedEndTime);
      let validEnd = isTimeValidInRange(newScheduleState.assignedEndTime, time, newScheduleState.assignedEndTime);
      let newScheduleValidState = {...scheduleInfoStateValid};
      newScheduleValidState.startTime = validStart;
      newScheduleValidState.endTime = validEnd;
      setScheduleInfoStateValid(newScheduleValidState);
    }
  }


  const setScheduledEndTime = (time: Date | string | null) => {
    setFormTouched(true);
    let newScheduleState = {...scheduleObjectInfoState};
    let endTime = formatDateTime(time!.toString())!;
    newScheduleState.assignedEndTime = endTime;
    setScheduleObjectInfoState(newScheduleState);
    /*checkIfWorkerAlreadyScheduled(selectedScheduleNames, newScheduleState.assignedStartTime, endTime);*/

    if(time != null){
      let validEnd = isTimeValidInRange(time, newScheduleState.assignedStartTime, time);
      let validStart = isTimeValidInRange(newScheduleState.assignedStartTime, newScheduleState.assignedStartTime, time);
      let newScheduleValidState = {...scheduleInfoStateValid};
      newScheduleValidState.endTime = validEnd;
      newScheduleValidState.startTime = validStart;
      setScheduleInfoStateValid(newScheduleValidState);
    }
  }

  const isFormValid = () => {
    return formTouched &&
        //scheduleTimesOverlappingOnDay.length === 0 &&
        scheduleInfoStateValid.employeeSelected && scheduleInfoStateValid.scheduleDate &&
        scheduleInfoStateValid.endDate && scheduleInfoStateValid.startTime &&
        scheduleInfoStateValid.endTime;
  };

  return (
      <Fragment>
        { showLoading ? (
            <Box sx={{marginTop:'200px'}}>
              <LoadingSpinnerContainer showLoading={showLoading} />
            </Box>
        ) : (
            <Dialog
                sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
                maxWidth="xs"
                open={props.isOpen}
            >
              <DialogTitle style={{color:DARK_NAVY, fontWeight:"bold"}}>{props.title}</DialogTitle>

              <FormControl component="fieldset">
                <DialogContent dividers>

                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <FormControl fullWidth>
                      <InputLabel id="assignees">Employees</InputLabel>
                      <Select
                          sx={{mb:2}}
                          labelId="assignees"
                          id="assignees"
                          multiple={true}
                          input={<OutlinedInput id="select-multiple-chip" label="assignees" />}
                          value={scheduleObjectInfoState.employeeNames}
                          renderValue={(selected) => (
                              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                    <Chip key={value} label={value} />
                                ))}
                              </Box>
                          )}
                          onChange={onAssigneesChange}
                          MenuProps={MenuMultiSelectProps}
                      >
                        { orgMembers.filter(mem => mem.role !== 'ORG_CONTRACT').map((member: UserInfo) => (
                            <MenuItem
                                key={member.userId}
                                value={member.firstName + ' ' + member.lastName}
                            >
                              <Checkbox checked={scheduleObjectInfoState.employeeNames.indexOf(member.firstName + ' ' + member.lastName) > -1} />
                              <ListItemText primary={member.firstName + ' ' + member.lastName} />
                            </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <Grid container spacing={2} style={{}}>
                      <Grid item xs={6}>
                        <DatePicker
                            label="Schedule Date"
                            value={scheduleObjectInfoState.scheduleDate}
                            onChange={setScheduledDate}
                            renderInput={(params) => <TextField {...params} />}
                        />
                        <small style={{color:'red', marginTop:0}} hidden={scheduleInfoStateValid.scheduleDate!}>
                          must be a valid date. Must be within year range of 2010-2040
                        </small>
                      </Grid>
                      <Grid item xs={6}>
                        <DatePicker
                            label="End Date"
                            value={scheduleObjectInfoState.scheduleEndDate}
                            onChange={setScheduledEndDate}
                            renderInput={(params) => <TextField {...params} />}
                        />
                        <small style={{color:'red', marginTop:0}} hidden={scheduleInfoStateValid.endDate!}>
                          must be a valid date and cannot take place before the start date. Must be within year range of 2010-2040
                        </small>
                      </Grid>
                    </Grid>


                    <Grid container spacing={2} style={{marginTop:'.35%'}}>
                      <Grid item xs={6}>
                        <TimePicker
                            label="Start Time"
                            value={scheduleObjectInfoState.assignedStartTime}
                            onChange={(newValue) => {
                              setScheduledStartTime(newValue);
                            }}
                            PopperProps={{placement:'top'}}
                            inputFormat={"hh:mm a"}
                            toolbarFormat='yyyy-MM-dd'
                            renderInput={(params) => <TextField {...params} />}
                        />
                        <small style={{color:'red', marginTop:0}} hidden={scheduleInfoStateValid.startTime || !formTouched}>
                          must be a valid time and less than the end time
                        </small>
                      </Grid>
                      <Grid item xs={6}>
                        <TimePicker
                            label="End Time"
                            value={scheduleObjectInfoState.assignedEndTime}
                            onChange={(newValue) => {
                              setScheduledEndTime(newValue);
                            }}
                            PopperProps={{placement:'top'}}
                            inputFormat={"hh:mm a"}
                            toolbarFormat='yyyy-MM-dd'
                            renderInput={(params) => <TextField {...params} />}
                        />
                        <small style={{color:'red', marginTop:0}} hidden={scheduleInfoStateValid.endTime || !formTouched}>
                          must be a valid time and greater than the start time
                        </small>
                      </Grid>
                    </Grid>

                    { scheduleTimesOverlappingOnDay.length > 0 &&
                    <Fragment>
                      <Alert variant="outlined" severity="error" sx={{mt:1}}>
                        {
                          scheduleTimesOverlappingOnDay.map((name, index) => (
                              <div style={{color: 'red', marginTop: 0}}>
                                <small>{'This shift overlaps a shift already scheduled for ' + name}</small>
                              </div>
                          ))
                        }
                      </Alert>
                    </Fragment>
                    }
                  </LocalizationProvider>


                </DialogContent>
                <DialogActions>
                  <Button style={{color:DARK_NAVY}} autoFocus onClick={cancelSchedule}>
                    Cancel
                  </Button>
                  <Button color={"inherit"}
                         type={'button'}
                         sx={{cursor: !(isFormValid()) ? 'not-allowed' : 'pointer',
                           bgcolor: isFormValid() ? LIME_GREEN : grey[200],
                           borderColor: isFormValid() ? DARK_NAVY : LIME_GREEN,
                           color: DARK_NAVY
                         }}
                         variant={isFormValid() ? "contained" : "outlined"}
                         onClick={handleScheduleSubmit}>
                    Submit
                  </Button>


                  {/*<Button style={{cursor: !isFormValid() ? 'not-allowed' : 'pointer'}}
                          variant={isFormValid() ? "contained" : "text"}
                          onClick={handleScheduleSubmit}>Submit</Button>*/}
                </DialogActions>
              </FormControl>
            </Dialog>
        )}
      </Fragment>
  );
}

export default ScheduleDialog;