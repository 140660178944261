import {TaskInfo} from "../../types/task/TaskTypes";
import {IconButton, Stack} from "@mui/material";
import TaskActions from "./TaskActions";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import React from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../store/storeIndex";


type Props = {
  task:TaskInfo,
  handleTaskSelected: (task: TaskInfo) => void,
  taskDialogOpen: boolean,
  setTaskDialogOpen: (value:boolean) => void
}

const TaskActionButtonsStack:React.FC<Props> = (props) => {
  const selectedDate = useSelector((state: RootState) => state.taskInfo.selectedDate);

  return (
      <Stack direction="row" spacing={0}>
        <TaskActions
            task={props.task}
            selectedDate={new Date(selectedDate)}
            taskDialogOpen={props.taskDialogOpen}
            setTaskDialogOpen={props.setTaskDialogOpen}
        />
        <IconButton aria-label="more"
                    onClick={() => props.handleTaskSelected(props.task)}>
          <MoreVertIcon color={"action"}/>
        </IconButton>
      </Stack>
  );
}

export default TaskActionButtonsStack;