import React, {useEffect, useState} from "react";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack} from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import {TaskInfo} from "../../types/task/TaskTypes";
import TaskActions from "./TaskActions";
import TaskInfoAccordian from "./TaskInfoAccordian";
import {taskInfoActions} from "../../store/storeIndex";
import {useDispatch} from "react-redux";
import {useAppThunkDispatch} from "../../store/storeHooks";
import CloseIcon from "@mui/icons-material/Close";

type Props = {
  task:TaskInfo,
  dialogOpen: boolean,
  setDialogOpen: (open:boolean) => void,
  selectedDate: Date | null,
  noScheduleNeeded?:boolean,
}

const TaskDetailDialog:React.FC<Props> = (props) => {
  let dispatch = useAppThunkDispatch();

  const [taskLocal, setTaskLocal] = useState(props.task);

  /*useEffect(() => {
    console.log("modal is open use effect")
    dispatch(taskInfoActions.setIsModalOpen({value: props.dialogOpen}));
  }, [props.dialogOpen])*/

  useEffect(() => {
    console.log("update update task")
    console.log(props.dialogOpen)
    if (props.dialogOpen) {
      setTaskLocal(props.task);
    }
  }, [props.task])

  const closeDialog = () => {
    dispatch(taskInfoActions.setIsModalOpen({value: false}));
    props.setDialogOpen(false);
  }

  return (
      <Dialog
          sx={{ '& .MuiDialog-paper': { width: '80%', height: 650, zIndex:13000 } }}
          maxWidth="md"
          open={props.dialogOpen}
      >
        <DialogTitle>
          {taskLocal.taskName}
          <IconButton
              edge="start"
              color="inherit"
              onClick={closeDialog}
              aria-label="close"
              sx={{position: 'absolute', right: 8, top: 8}}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers sx={{backgroundColor: "lightgrey"}}>
          <TaskInfoAccordian selectedTask={taskLocal}/>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={closeDialog} style={{float:"left"}}>
            Back
          </Button>
          <TaskActions
              task={taskLocal}
              selectedDate={props.selectedDate}
              taskDialogOpen={props.dialogOpen}
              setTaskDialogOpen={props.setDialogOpen}
              noScheduleNeeded={props.noScheduleNeeded}
          />
        </DialogActions>
      </Dialog>
  );
}

export default TaskDetailDialog;