import React, {useEffect, useState} from "react";
import Navbar from "../component/nav/Navbar";
import {Box, useMediaQuery} from "@mui/material";
import {useSelector} from "react-redux";
import {RootState, taskInfoActions} from "../store/storeIndex";
import {taskInfoInit} from "../store/task/taskInfoSlice";
import AlertToast from "../component/general/AlertToast";
import LoadingSpinnerFull from "../component/general/LoadingSpinnerFull";
import DashboardSideNav from "../component/task/DashboardSideNav";
import {useAppThunkDispatch} from "../store/storeHooks";
import {checkUserAdmin, checkUserNotOrdinaryWorker} from "../service/account/accountService";
import TaskManagement from "../component/task/TaskManagement";
import Footer from "../component/general/Footer";
import {laborTabs} from "../types/task/TaskTypes";
import JobBoardComponent from "../component/JobBoard/JobBoardComponent";
import {useHistory, useParams} from "react-router";
import {Role} from "../types/account/AccountTypes";

const LaborDashTutorialDialog = React.lazy(() => import("../component/task/LaborDashTutorialDialog"));
const TaskDetailDialog = React.lazy(() => import("../component/task/TaskDetailDialog"));
const BudgetingDash = React.lazy(() => import("../component/budget/BudgetingDash"));
const Reports = React.lazy(() => import("../component/reports/ReportsDash"));
const ScheduleComponent = React.lazy(() => import("../component/schedule/ScheduleComponent"));
const MonthlyCalendarComponent = React.lazy(() => import("../component/calendar/MonthlyCalendarComponent"));

interface ParamTypes {
  tab: string
}

const LaborPage: React.FC = () => {
    let history = useHistory();
    let dispatch = useAppThunkDispatch();
    const isWindowLarge = useMediaQuery('(min-width:800px)');
    const isWindowSmall = useMediaQuery('(max-width:415px)');

    const {tab} = useParams<ParamTypes>();

    const laborTutorial = useSelector((state: RootState) => state.experience.showLaborDashboardTutorial);
    const fullMonthResponse = useSelector((state: RootState) => state.taskInfo.fullMonthResponse);
    const selectedDate = useSelector((state: RootState) => state.taskInfo.selectedDate);
    const orgInfo = useSelector((state: RootState) => state.orgInfo);
    const userInfo = useSelector((state: RootState) => state.userInfo);
    const showSideMenu = useSelector((state: RootState) => state.taskInfo.showSideMenu);
    const isModalOpen = useSelector((state: RootState) => state.taskInfo.isModalOpen);

    const [showLoading, setShowLoading] = useState(false);
    const [tabSelected, setTabSelected] = useState((tab && tab.length > 0) ? tab : "taskManagement");
    const [selectedTask, setSelectedTask] = React.useState(taskInfoInit);
    const [taskDialogOpen, setTaskDialogOpen] = React.useState(false);
    const [hidePageContent, setHidePageContent] = useState<boolean>(false);
    const [showSideMenuLocal, setShowSideMenuLocal] = useState(showSideMenu);

    const [isLaborTutorialDialogOpen, setIsLaborTutorialDialogOpen] = React.useState(false);
    const [activeTutorialStep, setActiveTutorialStep] = React.useState(1);

  useEffect(() => {
    document.title = "Labor Page";
    if (tabSelected === 'taskManagement' && !checkUserNotOrdinaryWorker(userInfo.userInfo)) {
      console.log('board1')
      console.log(userInfo.userInfo.role === Role.ORG_WORKER)
      history.push('/manage/labor/jobBoard');
    }
  }, []);

    useEffect(() => {
      setTabSelected((tab && tab.length > 0) ? tab : (checkUserNotOrdinaryWorker(userInfo.userInfo) ? "taskManagement" : "jobBoard"));
    }, [tab, tabSelected])

    useEffect(() => {
      let showSideNav = false;

      if (laborTutorial) {
        showSideNav = false;
      } else {
        console.log(isWindowLarge)
        showSideNav = isWindowLarge;
      }

      console.log(isWindowLarge)
      console.log(showSideNav)

      if (isModalOpen) {
        console.log("modal is open hide side")
        showSideNav = !isModalOpen;
      }
      dispatch(taskInfoActions.setShowSideMenu({show: showSideNav}));
    }, [isWindowLarge, isModalOpen])

    useEffect(() => {
      setHidePageContent(!isWindowLarge && showSideMenu);
      setShowSideMenuLocal(showSideMenu);
    }, [isWindowLarge, showSideMenu])

    useEffect(() => {
      if(checkUserNotOrdinaryWorker(userInfo.userInfo)){
        setIsLaborTutorialDialogOpen(laborTutorial);
        setTabSelected(laborTabs[0]);
        //dispatch(taskInfoActions.setShowSideMenu({show: laborTutorial ? true : isWindowLarge}));
      }
    }, [laborTutorial]);

    return (
        <div style={{backgroundColor:''}}>
          <Navbar children={<div></div>}/>
            <Box sx={{ display: 'flex', minHeight:'1000px'}}>
                {(showSideMenuLocal) &&
                    <DashboardSideNav tabSelected={tabSelected} setTabSelected={setTabSelected} />
                }

              {!hidePageContent &&
                <Box sx={{
                  p: 3, width: '100%', height: '100%', backgroundColor: hidePageContent ? "lightgrey" : "white",
                  marginRight: laborTutorial ? '35%' : 'inherit'
                }}>
                  {tabSelected === 'taskManagement' && (
                      <Box sx={{width: isWindowSmall ? '100%' : '100%', marginLeft: isWindowSmall ? "1%" : "0"}}>
                        <TaskManagement tabSelected={tabSelected}/>
                      </Box>
                  )}

                  {tabSelected === 'calendar' && (
                      <MonthlyCalendarComponent/>
                  )}

                  {/*                    { tabSelected === 'fullWeek' && (
                            <WeekViewComponent/>
                        )}*/}

                  {tabSelected === 'scheduling' && (
                      <ScheduleComponent/>
                  )}

                  {tabSelected === 'jobBoard' && (
                      <JobBoardComponent/>
                  )}

                  {tabSelected === 'reports' && checkUserNotOrdinaryWorker(userInfo.userInfo) && (
                      <Reports/>
                  )}

                  {tabSelected === 'budgeting' && checkUserNotOrdinaryWorker(userInfo.userInfo) && (
                      <BudgetingDash/>
                  )}

                    <AlertToast/>
                    <LoadingSpinnerFull showLoading={showLoading}/>
                </Box>
              }
            </Box>

            <TaskDetailDialog
                task={selectedTask}
                dialogOpen={taskDialogOpen}
                setDialogOpen={setTaskDialogOpen}
                selectedDate={new Date(selectedDate)}
            />

            {!hidePageContent &&
              <Box sx={{marginRight: laborTutorial ? '35%' : 'inherit'}}>
                  <Footer/>
              </Box>
            }

            <LaborDashTutorialDialog
                isOpen={isLaborTutorialDialogOpen}
                setIsOpen={setIsLaborTutorialDialogOpen}
                step={activeTutorialStep}
                setStep={setActiveTutorialStep}
                setTabSelected={setTabSelected} />

        </div>
    );
}

export default LaborPage;