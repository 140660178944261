import React, {Fragment, useEffect, useState} from "react";
import {Box, Card, CardContent, CardHeader, Divider, IconButton, Stack, useMediaQuery} from "@mui/material";
import Typography from "@mui/material/Typography";
import {ViewDay} from "@mui/icons-material";
import ViewWeekIcon from "@mui/icons-material/ViewWeek";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import TaskDisplayFilter from "./TaskDisplayFilter";
import TaskDetailDialog from "./TaskDetailDialog";
import {Priority, TaskInfo} from "../../types/task/TaskTypes";
import {taskInfoInit} from "../../store/task/taskInfoSlice";
import {useSelector} from "react-redux";
import {RootState, taskInfoActions} from "../../store/storeIndex";
import {CalendarViews, getTaskToDisplay} from "../../service/TasksHelper";
import {HtmlTooltip} from "../general/HtmlTooltip";
import LoadingSpinnerContainer from "../general/LoadingSpinnerContainer";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CopyDayDialog from "./CopyDayDialog";
import {useAppThunkDispatch} from "../../store/storeHooks";
import TaskDisplayCard from "./TaskDisplayCard";
import {checkUserNotOrdinaryWorker} from "../../service/account/accountService";
import {DARK_NAVY, GHOST_WHITE, GREEN, LIGHTER_GREY_BLUE, LIME_GREEN} from "../../service/generic/ColorScheme";
import MasterCopyDialog from "../CopyFunctionality/MasterCopyDialog";

type Props = {
  showLoading: boolean,
  viewSelection: CalendarViews,
  setViewSelection: (value:CalendarViews) => void,
}

const TaskVerticalDisplay:React.FC<Props> = (props) => {
  let dispatch = useAppThunkDispatch();
  const isWindowXL = useMediaQuery('(min-width:1100px)');
  const userInfo = useSelector((state: RootState) => state.userInfo.userInfo);
  const orgInfo = useSelector((state: RootState) => state.orgInfo);
  const tasks = useSelector((state: RootState) => state.taskInfo);
  const selectedDate = useSelector((state: RootState) => state.taskInfo.selectedDate);
  const taskPriorityFilterGlobal = useSelector((state: RootState) => state.taskInfo.taskPriorityFilter);

  const [taskPriorityFilterLocal, setTaskPriorityFilterLocal] = React.useState("");
  const [openCopyDayDialog, setOpenCopyDayDialog] = useState(false);
  const [selectedTask, setSelectedTask] = React.useState(taskInfoInit);
  const [searchText, setSearchText] = React.useState('');
  const [filterCompleted, setFilterCompleted] = React.useState(true);
  const [filterUncompleted, setFilterUncompleted] = React.useState(true);
  const [filteringVisible, setFilterVisible] = React.useState(false);
  const [taskDialogOpen, setTaskDialogOpen] = React.useState(false);
  const [disableButtons, setDisableButtons] = React.useState(false);
  const [openMasterCopyDialog, setOpenMasterCopyDialog] = useState(false);
  const [showOpenScheduleButton, setShowOpenScheduleButton] = useState(true);

  const showTask = (task: TaskInfo) => {
    return (searchText === '' || (searchText && task.taskName.toLowerCase().includes(searchText.toLowerCase()))) &&
        ((task.completed && filterCompleted) || (!task.completed && filterUncompleted));
  }

  useEffect(() => {
    if (selectedTask && selectedTask.taskName) {
      let matchingTasks = tasks.monthlyTasks?.filter(mt => mt.assignedTaskId === selectedTask.assignedTaskId);
      if (matchingTasks) {
        setSelectedTask(matchingTasks[0]);
      }
    }
  }, [tasks.monthlyTasks])

  useEffect(() => {
    setTaskPriorityFilterLocal(taskPriorityFilterGlobal);
  }, [taskPriorityFilterGlobal])

  useEffect(() => {
    if (orgInfo.allSelectedAccessCodes!.length > 0) {
      setDisableButtons(orgInfo.allSelectedAccessCodes!.length > 1);
    }
  }, [selectedDate, orgInfo.allSelectedAccessCodes]);

  const handleTaskSelected = (task: TaskInfo) => {
    dispatch(taskInfoActions.setIsModalOpen({value: true}));
    //dispatch(taskInfoActions.setShowSideMenu({show: false}));
    setSelectedTask(task);
    setTaskDialogOpen(true);
  }

  const openCopyDayDialogModal = () => {
    if(checkUserNotOrdinaryWorker(userInfo)){
      setOpenCopyDayDialog(true);
    }
  }

  const priorityMap = new Map();
  priorityMap.set(Priority.LOW, 1);
  priorityMap.set(Priority.MID, 2);
  priorityMap.set(Priority.HIGH, 3);

  const priorityMapMed = new Map();
  priorityMapMed.set(Priority.LOW, 1);
  priorityMapMed.set(Priority.MID, 3);
  priorityMapMed.set(Priority.HIGH, 2);

  const sortTasksOnPriority = (a:TaskInfo, b:TaskInfo) =>  {
    if(taskPriorityFilterLocal === "LOW"){
      return priorityMap.get(a.priority) > priorityMap.get(b.priority) ? 1 : -1;
    }
    else if(taskPriorityFilterLocal === "MID"){
      return priorityMapMed.get(a.priority) < priorityMapMed.get(b.priority) ? 1 : -1;
    }
    else if(taskPriorityFilterLocal === "HIGH"){
      return priorityMap.get(a.priority) < priorityMap.get(b.priority) ? 1 : -1;
    }
    return a.completed ? 1 : -1;
  }

  const HeaderActionButtons = () => {
    return (
        <Stack direction={"row"} spacing={1}>
          <HtmlTooltip title={
            <Fragment>
              <Typography>Copy Day</Typography>
              <em>Copy Today's Items to Another Day</em>
            </Fragment>
          }>
            <IconButton aria-label="more"
                        sx={{backgroundColor: "inherit", cursor:'pointer', color:DARK_NAVY}}
                        onClick={() => openCopyDayDialogModal()}>
              <ContentCopyIcon />
            </IconButton>
          </HtmlTooltip>
          <Divider orientation="vertical" flexItem />
          <HtmlTooltip title={
            <Fragment>
              <Typography>Day Filter</Typography>
              <em>View only Tasks for the Selected Day</em>
            </Fragment>
          }>
            <IconButton aria-label="more"
                        sx={{backgroundColor: props.viewSelection === CalendarViews.DAILY ? LIME_GREEN : "inherit", color:DARK_NAVY}}
                        onClick={() => props.setViewSelection(CalendarViews.DAILY)}>
              <ViewDay />
            </IconButton>
          </HtmlTooltip>
          <HtmlTooltip title={
            <Fragment>
              <Typography>Through End of Week Filter</Typography>
              <em>View Only Tasks from the Selected Day to the End of that Week (Sat @ 11:59 pm)</em>
            </Fragment>
          }>
            <IconButton aria-label="more"
                        sx={{backgroundColor: props.viewSelection === CalendarViews.WEEKLY ? LIME_GREEN : "inherit", color:DARK_NAVY}}
                        onClick={() => props.setViewSelection(CalendarViews.WEEKLY)}>
              <ViewWeekIcon />
            </IconButton>
          </HtmlTooltip>
          <HtmlTooltip title={
            <Fragment>
              <Typography>Through End of Month Filter</Typography>
              <em>View Only Tasks from the Selected Day to the End of that Month</em>
            </Fragment>
          }>
            <IconButton aria-label="more"
                        sx={{backgroundColor: props.viewSelection === CalendarViews.MONTHLY ? LIME_GREEN : "inherit", color:DARK_NAVY}}
                        onClick={() => props.setViewSelection(CalendarViews.MONTHLY)}>
              <CalendarTodayIcon />
            </IconButton>
          </HtmlTooltip>
          <Divider orientation="vertical" flexItem />
          <HtmlTooltip title={
            <Fragment>
              <Typography>Filter and Search</Typography>
            </Fragment>
          }>
            <IconButton aria-label="more" sx={{color:DARK_NAVY}}
                        onClick={() => setFilterVisible(!filteringVisible)}>
              <FilterAltIcon />
            </IconButton>
          </HtmlTooltip>
        </Stack>
    );
  }

  return (
      <Card style={{display:  'flex', flexDirection: 'column',
          width:'100%', backgroundColor:LIGHTER_GREY_BLUE}}>
        <CardHeader
            title={
              <Fragment>
                <Stack direction={"row"} style={{marginBottom:5}}>
                  <Typography sx={{mt:1, color:DARK_NAVY}} component="div" variant="h5">
                    {props.viewSelection} Forward Tasks
                  </Typography>
                </Stack>
                <Typography variant="caption" component="div">
                  <Stack direction={"row"} spacing={1}
                         divider={<Divider orientation={"vertical"} sx={{backgroundColor:'black'}} />}>
                    <Card style={{backgroundColor:LIME_GREEN, width:'20%', textAlign:"center"}}>
                      <b style={{margin:5, color:"white"}}>3rd</b>
                    </Card>
                    <Card style={{backgroundColor:GREEN, width:'20%', textAlign:"center"}}>
                      <b style={{margin:5, color:"white"}}>2nd</b>
                    </Card>
                    <Card style={{backgroundColor:DARK_NAVY, width:'20%', textAlign:"center"}}>
                      <b style={{margin:5, color:"white"}}>1st</b>
                    </Card>
                    {/*<Box sx={{color:LIME_GREEN}}><b>LOW</b></Box>
                    <Box sx={{color:GREEN}}><b>MED</b></Box>
                    <Box sx={{color:DARK_NAVY}}><b>HIGH</b></Box>*/}
                  </Stack>
                </Typography>

                {!isWindowXL &&
                    <Box sx={{display:'flex', justifyContent:'center'}}>
                      <HeaderActionButtons/>
                    </Box>
                }
              </Fragment>
            }
            action = {
              <Fragment>
                {isWindowXL &&
                  <HeaderActionButtons/>
                }
              </Fragment>
            }
        />
        <CardContent style={{minHeight:'200px', maxHeight:'600px', overflowY:"auto"}}> {/*style={{maxHeight:'600px', overflowY:"auto"}}*/}
          {!props.showLoading &&
          <Fragment>
            {filteringVisible &&
            <Card sx={{mb: 1, backgroundColor: GHOST_WHITE}}>
                <CardContent>
                    <TaskDisplayFilter
                        filterUnCompleted={filterUncompleted}
                        setFilterUnCompleted={setFilterUncompleted}
                        filterCompleted={filterCompleted}
                        setFilterCompleted={setFilterCompleted}
                        searchValue={searchText}
                        setSearchValue={setSearchText}
                        viewSelection={props.viewSelection}
                    />
                </CardContent>
            </Card>
            }

            {getTaskToDisplay(props.viewSelection, tasks)?.sort(sortTasksOnPriority).map((task, index) => {
              if (showTask(task)) {
                return (
                    <Fragment key={'task' + index}>
                      <TaskDisplayCard
                          task={task}
                          handleTaskSelected={handleTaskSelected}
                          taskDialogOpen={taskDialogOpen}
                          setTaskDialogOpen={setTaskDialogOpen}
                      />
                    </Fragment>
                )
              }
            })}
          </Fragment>
          }

          <LoadingSpinnerContainer showLoading={props.showLoading} />
        </CardContent>

        <CopyDayDialog
            isOpen={openCopyDayDialog} setIsOpen={setOpenCopyDayDialog}
            selectedDate={selectedDate}/>

        <TaskDetailDialog
            task={selectedTask}
            dialogOpen={taskDialogOpen}
            setDialogOpen={setTaskDialogOpen}
            selectedDate={new Date(selectedDate)}
        />

        <MasterCopyDialog
            isOpen={openMasterCopyDialog} setIsOpen={setOpenMasterCopyDialog} setshowScheduleButton={setShowOpenScheduleButton}
            selectedDate={selectedDate}/>

      </Card>
  );
}

export default TaskVerticalDisplay;