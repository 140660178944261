import {ScheduledWeekObjectResponse, ScheduleObject, TaskInfo} from "../../types/task/TaskTypes";
import React, {Fragment, useEffect, useState} from "react";
import {
  Alert, Box,
  Button, Checkbox, Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, Divider, FormControl,
  Grid, IconButton, InputLabel, List, ListItem, ListItemText, MenuItem, OutlinedInput, Select,
  Typography,
  useMediaQuery
} from "@mui/material";
import TaskInfoAccordian from "./TaskInfoAccordian";
import TaskActions from "./TaskActions";
import {taskManagementService} from "../../service/taskManagementService";
import {formatDate} from "../../service/generic/DateHelper";
import {displayAlert} from "../../store/alertSlice";
import {determineIfTokenError} from "../../store/account/authSlice";
import {useAppThunkDispatch} from "../../store/storeHooks";
import {MenuMultiSelectProps} from "../../types/generic/MaterialUISelect";
import {UserInfo} from "../../types/account/AccountTypes";
import {useSelector} from "react-redux";
import {RootState, taskInfoActions} from "../../store/storeIndex";
import {Menu} from "@mui/icons-material";
import {grey} from "@mui/material/colors";
import TaskDetailDialog from "./TaskDetailDialog";
import {taskInfoInit} from "../../store/task/taskInfoSlice";
import {SelectChangeEvent} from "@mui/material/Select";
import LoadingSpinnerContainer from "../general/LoadingSpinnerContainer";
import ValidButton from "../general/ValidButton";

type Props = {
  handleDelete: (scheduleInfo: ScheduleObject, tasks: TaskInfo[]) => void,
  scheduleInfo:ScheduleObject,
  dialogOpen: boolean,
  setDialogOpen: (open:boolean) => void,
  selectedDate: Date | null,
}

const ScheduleDetailsDialog:React.FC<Props> = (props) => {
  const dispatch = useAppThunkDispatch();
  const isWindowLarge = useMediaQuery('(min-width:800px)');

  const orgMembers = useSelector((state: RootState) => state.account.orgMembers);
  const availableWorkersToAssignOnSelectedDay = useSelector((state: RootState) => state.taskInfo.availableWorkersToAssignOnSelectedDay);
  const [tasksToBeReassignedOnDelete, setTasksToBeReassignedOnDelete] = useState<TaskInfo[]>([]);
  const [taskDetailSelected, setTaskDetailSelected] = useState(taskInfoInit);
  const [openTaskDetailDialog, setOpenTaskDetailDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    document.title = "Schedule Details Dialog";
  }, []);

  /*useEffect(() => {
    console.log("modal is open use effect")
    dispatch(taskInfoActions.setIsModalOpen({value: props.dialogOpen}));
  }, [props.dialogOpen])*/

  useEffect(() => {
    console.log("get tasks assigned to work on day");
    if (props.scheduleInfo.employeeId && props.dialogOpen) {
      setIsLoading(true);
      taskManagementService.getTasksAssignedToWorkerOnDay(formatDate(props.scheduleInfo.scheduleDate!)!, props.scheduleInfo.employeeId)
          .then(resp => {
            [...resp].map((task, index) => {
              task.assignedUserDisplayNames = task.assignedUserDisplayNames.filter(name => name !== props.scheduleInfo.employeeName);
              task.assignedUserIds = task.assignedUserIds.filter(id => id !== props.scheduleInfo.employeeId);
              resp[index] = task;
            });
            setTasksToBeReassignedOnDelete(resp);
          }).catch(e => {
            dispatch(determineIfTokenError(e));
          }).finally(() => setIsLoading(false));
    }
  }, [props.scheduleInfo, props.dialogOpen])

  const onTaskDetailDialogOpen = (task:TaskInfo) => {
    setTaskDetailSelected(task);
    setOpenTaskDetailDialog(true);
  }

  const onTaskAssigneesUpdate = (event: SelectChangeEvent<typeof taskInfoInit.assignedUserIds>, taskId:string) => {
    const {target: {value}} = event;
    let names = typeof value === 'string' ? value.split(',') : value;

    let newTasks = [...tasksToBeReassignedOnDelete];
    tasksToBeReassignedOnDelete.map((task, index) => {
      if (task.assignedTaskId === taskId) {
        let newTaskState = {...task};
        let userIds:string[] = [];
        if (names.includes('')) {
          names = [];
        }
        newTaskState.assignedUserDisplayNames = names;
        userIds = names
            .map(name => {
              return orgMembers.filter(member =>
                  name.includes(member.firstName) && name.includes(member.lastName))[0];
            })
            .flatMap(member => member ? member.userId : '');
        if (userIds.includes('')) {
          userIds = [];
        }
        newTaskState.assignedUserIds = userIds;
        newTasks[index] = newTaskState;
      }
    })
    setTasksToBeReassignedOnDelete(newTasks);
  }

  return (
      <Dialog
          sx={{ '& .MuiDialog-paper': { width: isWindowLarge ? '35%' : '80%',
              height: tasksToBeReassignedOnDelete.length === 0 ? 300 : 400, zIndex:900 } }}
          maxWidth="md"
          open={props.dialogOpen}
      >
        <DialogTitle style={{fontWeight:'bold'}}>Worker Tasks</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={1} sx={{mb:2}}>
            <Grid item xs={6} style={{textAlign:"center"}}>
              <Typography variant={'h6'} component={"div"}>
                {props.scheduleInfo.employeeName}
              </Typography>
            </Grid>
            <Grid item xs={6} style={{textAlign:"center"}}>
              <Typography variant={'h6'} component={"div"}>
                {props.scheduleInfo.scheduleDate}
              </Typography>
            </Grid>
          </Grid>

          { isLoading ? (<LoadingSpinnerContainer showLoading={isLoading} />) : (
            <Fragment>
              { tasksToBeReassignedOnDelete.length === 0 ? (
                <Alert severity="warning" color="warning" sx={{mb: 2}}>
                  <Typography variant={'body1'} component={"div"}>
                    Are you sure you want to remove <b>{props.scheduleInfo.employeeName}'s </b>
                    shift on <b>{props.scheduleInfo.scheduleDate}</b> from the schedule?
                  </Typography>
                </Alert>
              ) : (
                  <Fragment>
                    <Alert severity="warning" color="warning" sx={{mb: 2}}>
                      <Typography variant={'body1'} component={"div"}>
                        <b>{props.scheduleInfo.employeeName}</b> has tasks currently assigned to them on <b>{props.scheduleInfo.scheduleDate}</b>
                        , you will need to reassign workers to this task, or you can leave the task unassigned.
                      </Typography>
                    </Alert>

                    <List>
                      { tasksToBeReassignedOnDelete.map((task, index) => (
                          <Fragment>
                            <ListItem
                                secondaryAction={
                                  <IconButton edge="end" aria-label="delete" onClick={() => onTaskDetailDialogOpen(task)}
                                              sx={{mt:4, backgroundColor:grey[300]}}>
                                    <Menu/>
                                  </IconButton>
                                }
                            >
                              <ListItemText
                                  primary={
                                    <Fragment>
                                      <Box sx={{mb:2}}>{task.taskName}</Box>
                                      <Box sx={{pr:2}}>
                                        <FormControl fullWidth>
                                          <InputLabel id="assignees">Assignees</InputLabel>
                                          <Select
                                              labelId="assignees"
                                              id="assignees"
                                              multiple={true}
                                              input={<OutlinedInput id="select-multiple-chip" label="assignees" />}
                                              value={task.assignedUserDisplayNames}
                                              renderValue={(selected) => (
                                                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                    {!(!selected || selected.length === 0 || selected[0] === "") &&
                                                    <Fragment>
                                                      {selected.map((value) => (
                                                          <Chip key={value} label={value}/>
                                                      ))}
                                                    </Fragment>
                                                    }
                                                  </Box>
                                              )}
                                              onChange={(event: SelectChangeEvent<typeof taskInfoInit.assignedUserIds>) =>
                                                  onTaskAssigneesUpdate(event, task.assignedTaskId)}
                                              MenuProps={MenuMultiSelectProps}
                                          >
                                            <MenuItem key={''} value={''}
                                                      sx={{pointerEvents:availableWorkersToAssignOnSelectedDay.length > 0 ? "initial" : "none"}}>
                                              <Checkbox checked={task.assignedUserDisplayNames.length === 0} />
                                              <ListItemText primary={'Nobody'} />
                                            </MenuItem>
                                            { availableWorkersToAssignOnSelectedDay
                                                    .filter(m => props.scheduleInfo.employeeId !== m.userId).map((member: UserInfo) => (
                                                <MenuItem
                                                    key={member.userId}
                                                    value={member.firstName + ' ' + member.lastName}
                                                >
                                                  <Checkbox checked={task.assignedUserDisplayNames.indexOf(member.firstName + ' ' + member.lastName) > -1} />
                                                  <ListItemText primary={member.firstName + ' ' + member.lastName} />
                                                </MenuItem>
                                            ))}
                                          </Select>
                                        </FormControl>
                                      </Box>
                                    </Fragment>
                                  }
                              />
                            </ListItem>
                            { index !== tasksToBeReassignedOnDelete.length - 1 &&
                              <Divider sx={{my: 2}}/>
                            }
                          </Fragment>
                      ))}
                    </List>
                  </Fragment>
              )}
            </Fragment>
          )}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => props.setDialogOpen(false)} style={{float:"left"}}>
            Cancel
          </Button>
          <ValidButton text={'Delete Shift'}
               handleSubmit={() => {
                 if (!isLoading) {
                   props.handleDelete(props.scheduleInfo, tasksToBeReassignedOnDelete)
                 }
               }}
                isFormValid={() => !isLoading}
          />
        </DialogActions>

        <TaskDetailDialog task={taskDetailSelected}
                          dialogOpen={openTaskDetailDialog}
                          setDialogOpen={setOpenTaskDetailDialog}
                          selectedDate={new Date(taskDetailSelected.scheduledDate!)} />
      </Dialog>
  );
}

export default ScheduleDetailsDialog;