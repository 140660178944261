import React, {Fragment, useEffect, useState} from "react";
import {Checkbox, Chip, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput} from "@mui/material";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import Box from "@mui/material/Box";
import {getMultiSelectStyles, MenuMultiSelectProps} from "../../types/generic/MaterialUISelect";
import {useDispatch, useSelector} from "react-redux";
import {orgInfoActions, RootState} from "../../store/storeIndex";
import {useTheme} from "@mui/material/styles";
import {AccountParent} from "../../types/account/AccountTypes";
import {getAccountParentFromAccessCode} from "../../service/account/accountHelper";
import {useAppThunkDispatch} from "../../store/storeHooks";
import {green, grey} from "@mui/material/colors";
import {GREEN, LIGHT_GREY_BLUE, LIGHTER_GREY_BLUE} from "../../service/generic/ColorScheme";


const OrgAccountSelect: React.FC = () => {
  const theme = useTheme();
  let dispatch = useAppThunkDispatch();
  let isAccountLocation = window.location.pathname.includes('/account');

  const orgInfo = useSelector((state: RootState) => state.orgInfo);
  const [selected, setSelected] = useState(orgInfo.allSelectedAccessCodes);

  const handleAccountSelectChange = (event: SelectChangeEvent<typeof orgInfo.allSelectedAccessCodes>) => {
    const {target: { value }} = event;
    let accessCodes = typeof value === 'string' ? value.split(',') : value;

    if (!isAccountLocation) {
      if (orgInfo.orgAccounts?.length > 1) {
        if (wasParentAccountDeSelected(accessCodes!)) {
          accessCodes = [];
        } else if (isParentAccountNewlySelected(accessCodes!) || isAllSubAccountsSelected(accessCodes!)) {
          accessCodes = orgInfo.allAccountOptions?.flatMap(acc => acc.accessCode);
        } else {
          accessCodes = accessCodes!.filter(accessCode => accessCode !== orgInfo.orgInfo.accessCode);
        }
      } else {
        accessCodes = accessCodes!.filter(accessCode => accessCode !== orgInfo.orgInfo.accessCode);
      }
    }

    let selected: AccountParent[] = accessCodes!.map(ac => getAccountParentFromAccessCode(ac, orgInfo.orgInfo, orgInfo.orgAccounts)).flat();

    setSelected(accessCodes);
    dispatch(orgInfoActions.setSelectedAccounts({selectedAccounts: selected}));
    dispatch(orgInfoActions.setAllSelectedAccessCodes({accessCodes: accessCodes}));
  };

  const wasParentAccountDeSelected = (accessCodes: string[]) => {
    return selected?.includes(orgInfo.orgInfo.accessCode) && !accessCodes.includes(orgInfo.orgInfo.accessCode);
  }

  const isAllSubAccountsSelected = (accessCodes: string[]) => {
    for (let subAccount of orgInfo.orgAccounts) {
      if (!accessCodes.includes(subAccount.accessCode)) {
        return false;
      }
    }
    return true;
  }

  const isParentAccountNewlySelected = (accessCodes: string[]) => {
    if (!selected?.includes(orgInfo.orgInfo.accessCode)) {
      return accessCodes.filter(code => code === orgInfo.orgInfo.accessCode).length > 0;
    }
    return false;
  }

  const getAccountsOptions = () => {
    if (orgInfo.orgAccounts?.length > 1) {
      return orgInfo.allAccountOptions!;
    }
    return orgInfo.allAccountOptions!.filter(acc => acc.accessCode !== orgInfo.orgInfo.accessCode);
  }

  return (
      <Fragment>
        { orgInfo.allAccountOptions && orgInfo.allAccountOptions?.length > 0 &&
          <FormControl component="fieldset" sx={{mr: 1, mb: 2, width: '100%'}}>
            <InputLabel id="accountSelect">Select Account</InputLabel>
            <Select
                labelId="accountSelect"
                id="selectAccountPicker"
                multiple={true}
                input={<OutlinedInput id="select-account-chip" label="Select Account"/>}
                value={selected}
                renderValue={(selected) => (
                    <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                      {selected.map((value) => (
                          <Chip key={value} sx={{fontWeight:'bold',
                                backgroundColor: (value === orgInfo.orgInfo.accessCode) ? GREEN : LIGHTER_GREY_BLUE}}
                                label={orgInfo.allAccountOptions!.filter(option => option.accessCode === value)[0]?.name +
                                (value === orgInfo.orgInfo.accessCode ? " (All)" : "")}
                          />
                      ))}
                    </Box>
                )}
                onChange={handleAccountSelectChange}
                MenuProps={MenuMultiSelectProps}
            >
              {getAccountsOptions().map((option) => (
                  <MenuItem
                      key={option.accessCode}
                      value={option.accessCode}
                      style={getMultiSelectStyles(option.accessCode, selected!, theme)}
                  >
                    <Checkbox checked={selected!.indexOf(option.accessCode) > -1}/>
                    <ListItemText primary={option.name + (option.accessCode === orgInfo.orgInfo.accessCode ? " (Select All)" : "")}/>
                  </MenuItem>
              ))}
            </Select>

            <small style={{color:'red', marginTop:0}} hidden={selected!.length > 0}>
              You dont have an account selected
            </small>
          </FormControl>
        }
      </Fragment>
  );
}

export default OrgAccountSelect;