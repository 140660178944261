import React, {Fragment, useEffect, useState} from "react";
import {useAppThunkDispatch} from "../../store/storeHooks";
import {
  Alert,
  Box, Button, Card, CardContent, CardHeader,
  Checkbox,
  Chip, CircularProgress, Dialog, DialogActions,
  DialogContent,
  DialogTitle, Divider, FormControl, FormControlLabel, Grid, IconButton,
  ListItemText,
  OutlinedInput, Stack as MuiStack, Stack, TextField, Tooltip,
  useMediaQuery
} from "@mui/material";
import {formatDate} from "../../service/generic/DateHelper";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import {MenuMultiSelectProps} from "../../types/generic/MaterialUISelect";
import MenuItem from "@mui/material/MenuItem";
import {
  copyDayInfoValidInit,
  CopyDayObjectUtilResponse,
  copyScheduleInfoValidInit,
  CopyScheduleObjectUtilResponse, DayStats, ExtraMonthResponse,
  FullMonthResponse, FullWeekResponse,
  ScheduledWeekObjectResponse,
  ScheduleObject,
  TaskInfo, TaskResponse
} from "../../types/task/TaskTypes";
import {LoadingButton, LocalizationProvider, TimePicker} from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {
  copyDayUtilInfoInit,
  copyScheduleUtilInfoInit,
  masterCopyUtilInit,
  ScheduleUtilInfoInit
} from "../../store/task/taskInfoSlice";
import {Role, UserInfo} from "../../types/account/AccountTypes";
import {useSelector} from "react-redux";
import {RootState, scheduleActions, taskInfoActions} from "../../store/storeIndex";
import {taskManagementService} from "../../service/taskManagementService";
import {determineIfTokenError} from "../../store/account/authSlice";
import {scheduleService} from "../../service/scheduleService";
import LoadingSpinnerContainer from "../general/LoadingSpinnerContainer";
import DatePicker, {DateObject} from "react-multi-date-picker"
import {
  DARK_GREY_BLUE,
  DARK_NAVY,
  GHOST_WHITE,
  GREEN,
  LIGHT_GREY_BLUE,
  LIME_GREEN
} from "../../service/generic/ColorScheme";
import {grey} from "@mui/material/colors";
import {HtmlTooltip} from "../general/HtmlTooltip";
import {ArrowBackIosNew, ArrowForwardIos} from "@mui/icons-material";
import OrgAccountSelect from "../account/OrgAccountSelect";
import LoadingSpinnerFull from "../general/LoadingSpinnerFull";
import CalendarIndividialDayDialog from "../task/CalendarIndividualDayDialog";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AddIcon from '@mui/icons-material/Add';
import moment from "moment";
import LeslieButton from "../general/LeslieButton";

type Props = {
  isOpen: boolean,
  setIsOpen: (open: false) => void,
  selectedDate: string,
  setshowScheduleButton: (open: true) => void,
}

const MasterCopyDialog: React.FC<Props> = (props) => {
  let dispatch = useAppThunkDispatch();
  const isWindowLarge = useMediaQuery('(min-width:800px)');

  const scheduleDayObjectResponse = useSelector((state: RootState) => state.schedule.scheduleDayObjectResponse);
  const tasks = useSelector((state: RootState) => state.taskInfo);
  const fullMonthResponseGlobal = useSelector((state: RootState) => state.taskInfo.fullMonthResponse);
  const extraMonthResponse = useSelector((state: RootState) => state.taskInfo.extraMonthResponse);
  const [fullMonthInfoState, setFullMonthInfoState] = useState<FullMonthResponse>(fullMonthResponseGlobal);
  const [extraMonths, setExtraMonths] = useState<ExtraMonthResponse>(extraMonthResponse);
  const daysTasks = useSelector((state: RootState) => state.taskInfo.daysTasks);
  const selectedDate = useSelector((state: RootState) => state.taskInfo.selectedDate);
  const userInfo = useSelector((state: RootState) => state.userInfo);
  const orgInfo = useSelector((state: RootState) => state.orgInfo);
  const [showLoading, setShowLoading] = React.useState(false);
  const [selectedCalendarDayTaskListState, setSelectedCalendarDayTaskListState] = useState<TaskInfo[]>();
  const [selectedCalendarDayScheduledEmployeeListState, setSelectedCalendarDayScheduledEmployeeListState] = useState<ScheduleObject[]>();
  const [selectedCalendarDayStatsState, setSelectedCalendarDayStatsState] = useState<DayStats>();
  const [selectedDayObject, setSelectedDayObject] = useState<TaskResponse>();
  const [selectedCalendarDayDisplayNameState, setSelectedCalendarDayDisplayNameState] = useState<string>("");
  const [selectedCalendarDayDisplayDateState, setSelectedCalendarDayDisplayDateState] = useState<string>("");
  const [calendarDayDialogOpen, setCalendarDayDialogOpen] = React.useState(false);
  const showSideMenu = useSelector((state: RootState) => state.taskInfo.showSideMenu);
  const selectedCalendarDate = useSelector((state: RootState) => state.taskInfo.selectedCalendarDate);
  const scheduledWeekObjectResponseGlobal = useSelector((state: RootState) => state.schedule.scheduledWeekObjectResponse);
  const [masterCopyUtil, setMasterCopyUtil] = useState(masterCopyUtilInit);

  const [howManyExtraMonthsToShow, setHowManyExtraMonthsToShow] = useState<number>(0);

  useEffect(() => {
    document.title = "Copy Dialog";
    let newMasterCopyUtil = {...masterCopyUtil};
    newMasterCopyUtil.copyTasksCheckBox = true;
    newMasterCopyUtil.copyScheduledWorkers = true;
    setMasterCopyUtil(newMasterCopyUtil);
  }, []);

  useEffect(() => {
    setFullMonthInfoState(fullMonthResponseGlobal);
  }, [fullMonthResponseGlobal, masterCopyUtil]);

  useEffect(() => {
    clearCopyCalendar();
  }, [orgInfo.allSelectedAccessCodes]);

  useEffect(() => {
    console.log(extraMonths);
  }, [extraMonths]);

  useEffect(() => {
    console.log(masterCopyUtil.fromDates);
    console.log(masterCopyUtil.toDates);
  }, [masterCopyUtil]);

  useEffect(() => {
    console.log(howManyExtraMonthsToShow);
    if (orgInfo.allSelectedAccessCodes && orgInfo.allSelectedAccessCodes?.length > 0) {
      getExtraMonthResponse();
    }
  }, [howManyExtraMonthsToShow]);

  useEffect(() => {
    if (orgInfo.allSelectedAccessCodes!.length > 0) {
      getFullMonthResponse();
    }
  }, [selectedDate, orgInfo.allSelectedAccessCodes, tasks.monthlyTasks, scheduledWeekObjectResponseGlobal]);

  const cancelDialog = () => {
    clearCopyCalendar();
    console.log("closing modal")
    props.setIsOpen(false);
    props.setshowScheduleButton(true);
  };

  const openAnotherMonth = () => {
    if(howManyExtraMonthsToShow+1!=7){
      setHowManyExtraMonthsToShow(howManyExtraMonthsToShow+1);
    }
  }

  const getFullMonthResponse = () => {
    setShowLoading(true);
    taskManagementService.getFullMonthResponse(formatDate(selectedDate), orgInfo.allSelectedAccessCodes!)
        .then((monthList: FullMonthResponse) => {
          dispatch(taskInfoActions.setFullMonthResponse({fullMonthResponse: monthList}));
        })
        .catch(e => dispatch(determineIfTokenError(e)))
        .finally(() => setShowLoading(false));
  }

  const getExtraMonthResponse = () => {
    setShowLoading(true);
    taskManagementService.getExtraMonthResponse(formatDate(selectedDate), orgInfo.allSelectedAccessCodes!, howManyExtraMonthsToShow)
        .then((extraMonthList: ExtraMonthResponse) => {
          dispatch(taskInfoActions.setExtraMonthResponse({extraMonthResponse: extraMonthList}));
          setExtraMonths(extraMonthList);
        })
        .catch(e => dispatch(determineIfTokenError(e)))
        .finally(() => setShowLoading(false));
  }

  const setSelectedDate = (date: Date | string | null) => {
    dispatch(taskInfoActions.setSelectedDate({selectedDate:date?.toString()}));
  }

  const selectNextMonth = () => {
    let currentSelection = new Date(selectedDate);
    let newDate = new Date();
    if (currentSelection.getMonth() == 11) {
      newDate.setDate(1);
      newDate.setMonth(0);
      newDate.setFullYear(currentSelection.getFullYear() + 1);
    } else {
      newDate.setDate(1);
      newDate.setMonth(currentSelection.getMonth() + 1);
      newDate.setFullYear(currentSelection.getFullYear());
    }
    setSelectedDate(newDate);
  }

  const selectPrevMonth = () => {
    let currentSelection = new Date(selectedDate);
    let newDate = new Date();
    if (currentSelection.getMonth() === 0) {
      newDate.setDate(1);
      newDate.setMonth(11);
      newDate.setFullYear(currentSelection.getFullYear() - 1);
    } else {
      newDate.setDate(1);
      newDate.setMonth(currentSelection.getMonth() - 1);
      newDate.setFullYear(currentSelection.getFullYear());
    }
    setSelectedDate(newDate);
  }

  const addDayToPasteList = (selectedDay:TaskResponse) => {
    let newMasterCopyUtil = {...masterCopyUtil};

    newMasterCopyUtil.toDates.push(selectedDay.date);
    let day1 = false;
    let day2 = false;
    let day3 = false;
    let day4 = false;
    let day5 = false;
    let splitDate = moment(selectedDay.date).format("MM/DD/YYYY").split("/");
    let dayNumber = splitDate[1]
    for(let i = 1; i < 7; i++){
      var intNumber = Number(dayNumber);
      let newNumber = intNumber + i;
      let prettyString = "";

      let lastDayOfMonth = new Date(new Date(moment(selectedDay.date).format("MM/DD/YYYY")).getFullYear(), new Date(moment(selectedDay.date).format("MM/DD/YYYY")).getMonth()+1, 0).toString().split(" ");
      console.log(lastDayOfMonth[2]);

      if(newNumber.toString().length != 2){
        prettyString = splitDate[2]+"-"+splitDate[0]+"-"+"0"+newNumber;
      }
      else{
        prettyString = splitDate[2]+"-"+splitDate[0]+"-"+newNumber;
      }

      var lastDayOfMonthNumber = Number(lastDayOfMonth[2]);
      if(newNumber <= lastDayOfMonthNumber){
        newMasterCopyUtil.toDates.push(prettyString);
      }
      else{
        var oldMonthNumber = Number(splitDate[0]);
        let newMonthNumber = oldMonthNumber + 1
        let newDayNumber = 0;
        if(!day1){
          day1 = true;
          newDayNumber = 1;
        }
        else if(!day2){
          day2 = true;
          newDayNumber = 2;
        }
        else if(!day3){
          day3 = true;
          newDayNumber = 3;
        }
        else if(!day4){
          day4 = true;
          newDayNumber = 4;
        }
        else if(!day5){
          day5 = true;
          newDayNumber = 5;
        }
        else{
          newDayNumber = 6;
        }
        if(newMonthNumber == 13){
          newMonthNumber = 1;
        }
        if(newMonthNumber.toString().length != 2 && newDayNumber.toString().length != 2){
          prettyString = splitDate[2]+"-"+"0"+newMonthNumber+"-"+"0"+newDayNumber;
        }
        else if(newMonthNumber.toString().length != 2 && newDayNumber.toString().length == 2){
          prettyString = splitDate[2]+"-"+"0"+newMonthNumber+"-"+newDayNumber;
        }
        else if(newMonthNumber.toString().length == 2 && newDayNumber.toString().length != 2){
          prettyString = splitDate[2]+"-"+newMonthNumber+"-"+"0"+newDayNumber;
        }
        else{
          prettyString = splitDate[2]+"-"+newMonthNumber+"-"+newDayNumber;
        }
        newMasterCopyUtil.toDates.push(prettyString);
      }
    }
    setMasterCopyUtil(newMasterCopyUtil);
  }

  const addDayToCopyList = (selectedDay:TaskResponse) => {
    let newMasterCopyUtil = {...masterCopyUtil};

    newMasterCopyUtil.fromDates.push(selectedDay.date);
    let day1 = false;
    let day2 = false;
    let day3 = false;
    let day4 = false;
    let day5 = false;
    let splitDate = moment(selectedDay.date).format("MM/DD/YYYY").split("/");
    let dayNumber = splitDate[1];
    for(let i = 1; i < 7; i++){
      var intNumber = Number(dayNumber);
      let newNumber = intNumber + i;
      let prettyString = "";

      let lastDayOfMonth = new Date(new Date(moment(selectedDay.date).format("MM/DD/YYYY")).getFullYear(), new Date(moment(selectedDay.date).format("MM/DD/YYYY")).getMonth()+1, 0).toString().split(" ");
      console.log(lastDayOfMonth[2]);

      if(newNumber.toString().length != 2){
        prettyString = splitDate[2]+"-"+splitDate[0]+"-"+"0"+newNumber;
      }
      else{
        prettyString = splitDate[2]+"-"+splitDate[0]+"-"+newNumber;
      }

      var lastDayOfMonthNumber = Number(lastDayOfMonth[2]);
      if(newNumber <= lastDayOfMonthNumber){
        newMasterCopyUtil.fromDates.push(prettyString);
      }
      else{
        var oldMonthNumber = Number(splitDate[0]);
        let newMonthNumber = oldMonthNumber + 1
        let newDayNumber = 0;
        if(!day1){
          day1 = true;
          newDayNumber = 1;
        }
        else if(!day2){
          day2 = true;
          newDayNumber = 2;
        }
        else if(!day3){
          day3 = true;
          newDayNumber = 3;
        }
        else if(!day4){
          day4 = true;
          newDayNumber = 4;
        }
        else if(!day5){
          day5 = true;
          newDayNumber = 5;
        }
        else{
          newDayNumber = 6;
        }
        console.log("newDayNumber: " + newDayNumber);
        if(newMonthNumber == 13){
          newMonthNumber = 1;
        }
        if(newMonthNumber.toString().length != 2 && newDayNumber.toString().length != 2){
          prettyString = splitDate[2]+"-"+"0"+newMonthNumber+"-"+"0"+newDayNumber;
        }
        else if(newMonthNumber.toString().length != 2 && newDayNumber.toString().length == 2){
          prettyString = splitDate[2]+"-"+"0"+newMonthNumber+"-"+newDayNumber;
        }
        else if(newMonthNumber.toString().length == 2 && newDayNumber.toString().length != 2){
          prettyString = splitDate[2]+"-"+newMonthNumber+"-"+"0"+newDayNumber;
        }
        else{
          prettyString = splitDate[2]+"-"+newMonthNumber+"-"+newDayNumber;
        }
        newMasterCopyUtil.fromDates.push(prettyString);
      }
    }

    setMasterCopyUtil(newMasterCopyUtil);
  }

  const checkBorder = (selectedDay:TaskResponse) => {
    let dateSelected = "";

    for(var i = 0; i < masterCopyUtil.fromDates.length; i++){
      if(moment(masterCopyUtil.fromDates[i]).format("MM/DD/YYYY") == moment(selectedDay.date).format("MM/DD/YYYY")){
        dateSelected = DARK_NAVY;
      }
    }
    for(var i = 0; i < masterCopyUtil.toDates.length; i++){
      if(moment(masterCopyUtil.toDates[i]).format("MM/DD/YYYY") == moment(selectedDay.date).format("MM/DD/YYYY")){
        dateSelected = LIME_GREEN;
      }
    }
    return dateSelected;
  }

  const handleDaySelected = (weekDayDisplayName:string, taskList:TaskInfo[],
                             scheduledEmployeeList:ScheduleObject[], dayStats:DayStats, selectedDay:TaskResponse) => {
    setShowLoading(true);
    var splitted = weekDayDisplayName.split(":", 2);
    setSelectedCalendarDayDisplayNameState(splitted[0]);
    let splitDate = splitted[1].split("-",3);
    let formattedDisplayDate = splitDate[1]+"/"+splitDate[2]+"/"+splitDate[0];
    setSelectedCalendarDayDisplayDateState(formattedDisplayDate);
    setSelectedCalendarDayTaskListState(taskList);
    setSelectedCalendarDayScheduledEmployeeListState(scheduledEmployeeList);
    setSelectedCalendarDayStatsState(dayStats);
    setSelectedDayObject(selectedDay);
    let formattedDate = formatDate(selectedDay.date)
    dispatch(taskInfoActions.setDaysTasks({daysTasks: taskList}));
    dispatch(taskInfoActions.setSelectedCalendarDate({value: formattedDate}));
    setShowLoading(false);
    setCalendarDayDialogOpen(true);
  }

  const showAddButtonLogic = (index1: number, index2: number) => {
    let addButtonList = ["00", "10", "20", "30", "40", "50"];
    return addButtonList.includes(index1.toString() + index2);
    /*let show = false;
    if(index1+""+index2 === "00"){
      show = true
    }
    if(index1+""+index2 === "10"){
      show = true
    }
    if(index1+""+index2 === "20"){
      show = true
    }
    if(index1+""+index2 === "30"){
      show = true
    }
    if(index1+""+index2 === "40"){
      show = true
    }
    if(index1+""+index2 === "50"){
      show = true
    }
    return show;*/
  }

  const isFormValid = () => {
    let toDatesValid = false;
    if(masterCopyUtil.toDates.length != 0){
      toDatesValid = true;
    }
    let fromDatesValid = false;
    if(masterCopyUtil.fromDates.length != 0){
      fromDatesValid = true;
    }
    let checkBoxesValid = false;
    if(masterCopyUtil.copyTasksCheckBox || masterCopyUtil.copyScheduledWorkers){
      checkBoxesValid = true;
    }

    return checkBoxesValid && toDatesValid && fromDatesValid;
  };

  const onChangeTaskCheckBox = () => {
    let newMasterCopyUtil = {...masterCopyUtil};
    newMasterCopyUtil.copyTasksCheckBox = !masterCopyUtil.copyTasksCheckBox;
    setMasterCopyUtil(newMasterCopyUtil);
  }

  const onChangeScheduleCheckBox = () => {
    let newMasterCopyUtil = {...masterCopyUtil};
    newMasterCopyUtil.copyScheduledWorkers = !masterCopyUtil.copyScheduledWorkers;
    setMasterCopyUtil(newMasterCopyUtil);
  }

  const onChangeToDate = (dates: DateObject[] | null) => {
    let dateArray = [];
    for(let date of dates!){
      dateArray.push(formatDate(date.toDate())).toString();
    }
    let newMasterCopyUtil = {...masterCopyUtil};
    let dateArrayNonNulls:string[] = [];
    for (let date of dateArray) {
      if (date !== null) {
        dateArrayNonNulls.push(date);
      }
    }
    newMasterCopyUtil.toDates = dateArrayNonNulls;
    setMasterCopyUtil(newMasterCopyUtil);

    /*let valid = isDateValidInRange(date!, date!, date!);*/
/*    let newCopyScheduleValidState = {...copyScheduleInfoStateValid};
    newCopyScheduleValidState.toDate = dates != null;
    setCopyScheduleInfoStateValid(newCopyScheduleValidState);*/
  }

  const handleMasterCopySubmit = () => {
    if(isFormValid()){
      setShowLoading(true);
      scheduleService.masterCopySubmit(masterCopyUtil, orgInfo.allSelectedAccessCodes!, formatDate(selectedDate))
          .then((resp: FullMonthResponse) => {
            dispatch(taskInfoActions.setFullMonthResponse({fullMonthResponse: resp}));
            cancelDialog();
          }).catch(e => {
        dispatch(determineIfTokenError(e));
      });
      setShowLoading(false);
    }
  }

  const clearCopyCalendar = () => {
    setHowManyExtraMonthsToShow(0);
    let newMasterCopyUtil = {...masterCopyUtil};
    newMasterCopyUtil.fromDates = [];
    newMasterCopyUtil.toDates = [];
    setMasterCopyUtil(newMasterCopyUtil);
  }

  type MonthCalendarCardProps = {
    day: TaskResponse,
    index1: number,
    index2: number,
  }

  const MonthlyCalendarColumns = (props: MonthCalendarCardProps) => {
    return (
        <Card
              key={"CalendarDay" + props.index1 + "" + props.index2}
              style={{width:'100%', height:'170px'}}
              sx={{backgroundColor:props.day.showCurrentDateOutline ? LIGHT_GREY_BLUE : (props.day.dayInMonth ? GHOST_WHITE : "#cccccc"),
                  border:checkBorder(props.day) ? '3px solid' : 'none', borderColor:checkBorder(props.day)}}>
          <CardHeader sx={{pb:0}}
              title={
                <Typography variant="h6" component="div">
                  <Grid container>
                    <Grid item md={6}>
                      {props.day.dayNumber}
                    </Grid>
                    <Grid item md={6}
                          sx={{display: { xs: 'block', sx: 'block', md: 'block', lg: 'none' }}}>
                      <h5 style={{marginTop:'5px', marginLeft:'10px'}}>{props.day.dayInWeekDisplayName}</h5>
                    </Grid>
                  </Grid>
                </Typography>
              }
              action={
                <MuiStack direction={'row'} sx={{pt:0}}
                          divider={<Divider orientation={"vertical"} style={{color:"black"}}/> }>
                  <Fragment>
                    { showAddButtonLogic(props.index1, props.index2) && masterCopyUtil.fromDates.length === 0 &&
                    <HtmlTooltip style={{color:DARK_GREY_BLUE}} title={
                      <Fragment>
                        <Typography>Copy Day</Typography>
                        <em>Select this day to be copied.</em>
                      </Fragment>
                    }>
                      <IconButton aria-label="more"
                                  sx={{backgroundColor: "inherit", cursor: 'pointer'}}
                                  onClick={() => {
                                    addDayToCopyList(props.day)
                                  }}>
                        <AddIcon />
                      </IconButton>
                    </HtmlTooltip>
                    }
                    { showAddButtonLogic(props.index1, props.index2) && masterCopyUtil.fromDates.length !== 0 && !checkBorder(props.day) &&
                    <HtmlTooltip style={{color:DARK_NAVY}} title={
                      <Fragment>
                        <Typography>Copy Day</Typography>
                        <em>Select this day to be copied.</em>
                      </Fragment>
                    }>
                      <IconButton aria-label="more"
                                  sx={{backgroundColor: "inherit", cursor: 'pointer'}}
                                  onClick={() => {
                                    addDayToPasteList(props.day)
                                  }}>
                        <AddIcon />
                      </IconButton>
                    </HtmlTooltip>
                    }
                  </Fragment>
                </MuiStack>
              }/>
          <hr/>
          { showLoading ? (
              <Box sx={{marginTop:'200px'}}>
                <LoadingSpinnerContainer showLoading={showLoading} />
              </Box>
          ) : (
              <CardContent onClick={() => handleDaySelected(props.day.weekNameDisplay, props.day.taskList,
                  props.day.scheduledEmployeesList, props.day.dayStats, props.day)} style={{cursor:"pointer"}}>
                <Box>
                  <Typography variant="h6" component="div"
                              onClick={() => handleDaySelected(props.day.weekNameDisplay, props.day.taskList,
                                  props.day.scheduledEmployeesList, props.day.dayStats, props.day)}
                              style={{cursor: "pointer"}}>

                  </Typography>
                  <Typography variant="body1" component="div" color={"dimgrey"}
                              onClick={() => handleDaySelected(props.day.weekNameDisplay, props.day.taskList,
                                  props.day.scheduledEmployeesList, props.day.dayStats, props.day)}
                              style={{cursor: "pointer"}}>

                    {props.day.dayStats.numberOfTasks > 0 &&
                    <Fragment>
                      <HtmlTooltip title={
                        <Fragment>
                          <Typography>Total Tasks</Typography>
                          <em>Number of tasks both completed and uncompleted for this day.</em>
                        </Fragment>
                      }>
                        <Chip label={"Task" + ((props.day.dayStats.numberOfTasks > 1) ? "s" : "")} variant="outlined"
                              sx={{cursor:"pointer", mb:1, color:"black", backgroundColor:LIME_GREEN}}
                              icon={
                                <div>
                                  <Box sx={{mx:1, color:"black"}}><b>{props.day.dayStats.numberOfTasks}</b></Box>
                                </div>
                              }
                        />
                      </HtmlTooltip>
                    </Fragment>
                    }

                    {props.day.dayStats.numberOfScheduledWorkers > 0 &&
                    <Fragment>
                      <HtmlTooltip title={
                        <Fragment>
                          <Typography>Scheduled Workers</Typography>
                          <em>Number of workers scheduled for this day.</em>
                        </Fragment>
                      }>
                        <Chip label={"Worker" + ((props.day.dayStats.numberOfScheduledWorkers > 1) ? "s" : "")} variant="outlined"
                              sx={{cursor:"pointer", mb:1, color:"black", backgroundColor:GREEN}}
                              icon={
                                <div>
                                  <Box sx={{mx:1, color:"black"}}><b>{props.day.dayStats.numberOfScheduledWorkers}</b></Box>
                                </div>
                              }/>
                      </HtmlTooltip>
                    </Fragment>
                    }
                  </Typography>
                </Box>
              </CardContent>
          )}
        </Card>
    );
  }



  return (
      <Fragment>
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: isWindowLarge ? '100%' : '100%'} }}
            maxWidth="xl"
            open={props.isOpen}
        >
          <DialogTitle style={{fontWeight:'bold'}}>Master Copy</DialogTitle>
          <DialogContent sx={{p:2}}>

            { userInfo.userInfo.accessCode === orgInfo.orgInfo.accessCode && userInfo.userInfo.role !== Role.ORG_WORKER &&
            <OrgAccountSelect/>
            }

            <Fragment>
              <Grid container spacing={2} sx={{textAlign:'center', fontWeight:'bold',p:2}}>
                <Grid item xs={6} sm={6} md={4} sx={{}}>
                  <HtmlTooltip title={
                    <Fragment>
                      <Typography>Copy Workers</Typography>
                      <em>Workers already scheduled will be ignored.</em>
                    </Fragment>
                  }>
                    <FormControlLabel sx={{mb:2}}
                                      label={'Copy Workers'}
                                      control={
                                        <Checkbox sx={{}}
                                                  checked={masterCopyUtil.copyTasksCheckBox}
                                                  onChange={() => onChangeTaskCheckBox()}
                                        />
                                      }
                    />
                  </HtmlTooltip>

                </Grid>
                <Grid item xs={6} sm={6} md={4} sx={{}}>
                  <FormControlLabel sx={{mb:2}}
                                    label={'Copy Tasks'}
                                    control={
                                      <Checkbox sx={{}}
                                                checked={masterCopyUtil.copyScheduledWorkers}
                                                onChange={() => onChangeScheduleCheckBox()}
                                      />
                                    }
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={4} sx={{}}>
                  <LeslieButton text={'Clear'}
                                width={'100%'}
                                height={"short"}
                                handleSubmit={() => clearCopyCalendar()}
                  />
                </Grid>
              </Grid>

              { masterCopyUtil.fromDates.length === 0 &&
              <Box sx={{}}>
                <Alert severity="info" color="info" sx={{mb: 2, textAlign: 'center'}}>
                  <Typography variant={'body1'} component={"div"}>
                    Click on the <AddIcon sx={{}}/> button of the week you wish to copy.
                  </Typography>
                </Alert>
              </Box>
              }
              { masterCopyUtil.fromDates.length !== 0 && masterCopyUtil.toDates.length === 0 &&
              <Alert severity="info" color="info" sx={{mb: 2}}>
                <Typography variant={'body1'} component={"div"}>
                  Click on the <AddIcon /> button of the week(s) you'd like to copy too.
                </Typography>
              </Alert>
              }

              <MuiStack direction={"row"}>
                <h2 style={{color:DARK_NAVY}}>{fullMonthInfoState.nameOfMonthDisplay}</h2>
                <h2 style={{color:DARK_NAVY}}> -  {fullMonthInfoState.yearDisplay}</h2>
              </MuiStack>

            </Fragment>

            <Fragment>
              {!showLoading &&
              <Fragment>
                <Grid container sx={{width: '100%', top: 0}} spacing={1}>
                  <Grid item xs={12} sm={12} md={12} lg={5} style={{paddingLeft: 0}}>
                    <Grid container sx={{width: '100%'}} spacing={0}>
                      <Grid item xs={12} sm={12} md={12} lg={4}
                            sx={{
                              px: .5,
                              display: {xs: 'none', sx: 'none', md: 'none', lg: 'block'}
                            }}>
                        <h4 style={{textAlign: "center", marginTop: 0}}>Sunday</h4>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={4}
                            sx={{
                              px: .5,
                              display: {xs: 'none', sx: 'none', md: 'none', lg: 'block'}
                            }}>
                        <h4 style={{textAlign: "center", marginTop: 0}}>Monday</h4>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={4}
                            sx={{
                              px: .5,
                              display: {xs: 'none', sx: 'none', md: 'none', lg: 'block'}
                            }}>
                        <h4 style={{textAlign: "center", marginTop: 0}}>Tuesday</h4>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sm={12} md={12} lg={7} style={{paddingLeft: 0}}>
                    <Grid container sx={{width: '100%'}}>
                      <Grid item xs={12} sm={12} md={12} lg={3}
                            sx={{
                              px: .5,
                              display: {xs: 'none', sx: 'none', md: 'none', lg: 'block'}
                            }}>
                        <h4 style={{textAlign: "center", marginTop: 0}}>Wednesday</h4>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={3}
                            sx={{
                              px: .5,
                              display: {xs: 'none', sx: 'none', md: 'none', lg: 'block'}
                            }}>
                        <h4 style={{textAlign: "center", marginTop: 0}}>Thursday</h4>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={3}
                            sx={{
                              px: .5,
                              display: {xs: 'none', sx: 'none', md: 'none', lg: 'block'}
                            }}>
                        <h4 style={{textAlign: "center", marginTop: 0}}>Friday</h4>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={3}
                            sx={{
                              px: .5,
                              display: {xs: 'none', sx: 'none', md: 'none', lg: 'block'}
                            }}>
                        <h4 style={{textAlign: "center", marginTop: 0}}>Saturday</h4>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container sx={{width: '100%'}} spacing={1}>
                  {fullMonthInfoState?.monthList && fullMonthInfoState?.monthList.map((week: FullWeekResponse, index1) => (
                      <Fragment>
                        <Grid item sm={12} md={12} lg={5} sx={{paddingLeft: 0}}>
                          <Grid container sx={{width: '100%'}} spacing={1}>
                            {week?.weekList.map((day: TaskResponse, index2) => (
                                <Fragment>
                                  {index2 < 3 &&
                                  <Grid item xs={12} sm={12} md={12} lg={4}>
                                    <MonthlyCalendarColumns day={day}
                                                            index1={index1}
                                                            index2={index2}/>
                                  </Grid>
                                  }
                                </Fragment>
                            ))}
                          </Grid>
                        </Grid>
                        <Grid item sm={12} md={12} lg={7} style={{paddingLeft: 0}}>
                          <Grid container sx={{width: '100%'}} spacing={1}>
                            {week?.weekList.map((day: TaskResponse, index2) => (
                                <Fragment>
                                  {index2 > 2 &&
                                  <Grid item xs={12} sm={12} md={12} lg={3}>
                                    <MonthlyCalendarColumns day={day}
                                                            index1={index1}
                                                            index2={index2}/>
                                  </Grid>
                                  }
                                </Fragment>
                            ))}
                          </Grid>
                        </Grid>
                      </Fragment>
                  ))}
                </Grid>

                <Grid container sx={{width: '100%'}} spacing={1}>
                  {extraMonths?.extraMonthList && extraMonths?.extraMonthList.map((month: FullMonthResponse) => (
                      <Fragment>
                        <Box sx={{mt:5}}>
                          <h3>{month.nameOfMonthDisplay} - {month.yearDisplay}</h3>
                        </Box>
                        <Grid container sx={{width: '100%'}} spacing={1}>
                          <Grid item xs={12} sm={12} md={12} lg={5} style={{paddingLeft: 0}}>
                            <Grid container sx={{width: '100%'}} spacing={0}>
                              <Grid item xs={12} sm={12} md={12} lg={4}
                                    sx={{
                                      px: .5,
                                      display: {xs: 'none', sx: 'none', md: 'none', lg: 'block'}
                                    }}>
                                <h4 style={{textAlign: "center", marginTop: 0}}>Sunday</h4>
                              </Grid>
                              <Grid item xs={12} sm={12} md={12} lg={4}
                                    sx={{
                                      px: .5,
                                      display: {xs: 'none', sx: 'none', md: 'none', lg: 'block'}
                                    }}>
                                <h4 style={{textAlign: "center", marginTop: 0}}>Monday</h4>
                              </Grid>
                              <Grid item xs={12} sm={12} md={12} lg={4}
                                    sx={{
                                      px: .5,
                                      display: {xs: 'none', sx: 'none', md: 'none', lg: 'block'}
                                    }}>
                                <h4 style={{textAlign: "center", marginTop: 0}}>Tuesday</h4>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item sm={12} md={12} lg={7} style={{paddingLeft: 0}}>
                            <Grid container sx={{width: '100%'}}>
                              <Grid item xs={12} sm={12} md={12} lg={3}
                                    sx={{
                                      px: .5,
                                      display: {xs: 'none', sx: 'none', md: 'none', lg: 'block'}
                                    }}>
                                <h4 style={{textAlign: "center", marginTop: 0}}>Wednesday</h4>
                              </Grid>
                              <Grid item xs={12} sm={12} md={12} lg={3}
                                    sx={{
                                      px: .5,
                                      display: {xs: 'none', sx: 'none', md: 'none', lg: 'block'}
                                    }}>
                                <h4 style={{textAlign: "center", marginTop: 0}}>Thursday</h4>
                              </Grid>
                              <Grid item xs={12} sm={12} md={12} lg={3}
                                    sx={{
                                      px: .5,
                                      display: {xs: 'none', sx: 'none', md: 'none', lg: 'block'}
                                    }}>
                                <h4 style={{textAlign: "center", marginTop: 0}}>Friday</h4>
                              </Grid>
                              <Grid item xs={12} sm={12} md={12} lg={3}
                                    sx={{
                                      px: .5,
                                      display: {xs: 'none', sx: 'none', md: 'none', lg: 'block'}
                                    }}>
                                <h4 style={{textAlign: "center", marginTop: 0}}>Saturday</h4>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        {month?.monthList && month?.monthList.map((week: FullWeekResponse, index1) => (
                            <Fragment>
                              <Grid item sm={12} md={12} lg={5} sx={{paddingLeft: 0}}>
                                <Grid container sx={{width: '100%'}} spacing={1}>
                                  {week?.weekList.map((day: TaskResponse, index2) => (
                                      <Fragment>
                                        {index2 < 3 &&
                                        <Grid item xs={12} sm={12} md={12} lg={4}>
                                          <MonthlyCalendarColumns day={day}
                                                                  index1={index1}
                                                                  index2={index2}/>
                                        </Grid>
                                        }
                                      </Fragment>
                                  ))}
                                </Grid>
                              </Grid>
                              <Grid item sm={12} md={12} lg={7} style={{paddingLeft: 0}}>
                                <Grid container sx={{width: '100%'}} spacing={1}>
                                  {week?.weekList.map((day: TaskResponse, index2) => (
                                      <Fragment>
                                        {index2 > 2 &&
                                        <Grid item xs={12} sm={12} md={12} lg={3}>
                                          <MonthlyCalendarColumns day={day}
                                                                  index1={index1}
                                                                  index2={index2}/>
                                        </Grid>
                                        }
                                      </Fragment>
                                  ))}
                                </Grid>
                              </Grid>
                            </Fragment>
                        ))}
                      </Fragment>
                  ))}
                </Grid>

              </Fragment>




              }
              <LoadingSpinnerFull showLoading={showLoading}/>

              <CalendarIndividialDayDialog
                  taskList={selectedCalendarDayTaskListState!}
                  dayStats={selectedCalendarDayStatsState!}
                  day={selectedDayObject!}
                  scheduledEmployeeList={selectedCalendarDayScheduledEmployeeListState!}
                  selectedDayDisplayName={selectedCalendarDayDisplayNameState}
                  selectedDayDisplayDate={selectedCalendarDayDisplayDateState}
                  isOpen={calendarDayDialogOpen} setIsOpen={setCalendarDayDialogOpen}/>
            </Fragment>

          </DialogContent>
          <DialogActions style={{backgroundColor:'#BDBDBD'}}>
            <Button autoFocus onClick={() => cancelDialog()}>
              Cancel
            </Button>
            <Button autoFocus onClick={() => openAnotherMonth()}>
              Next Month
            </Button>
            <LoadingButton loading={showLoading}
                           loadingIndicator={<CircularProgress color={"inherit"} sx={{color: "white"}} size={16}/>}
                           type={'button'}
                           sx={{cursor: !(isFormValid()) ? 'not-allowed' : 'pointer',
                             bgcolor: isFormValid() ? LIME_GREEN : grey[200],
                             borderColor: isFormValid() ? "none" : LIME_GREEN,
                             color: DARK_NAVY
                           }}
                           variant={isFormValid() ? "contained" : "outlined"}
                           onClick={handleMasterCopySubmit}>
              Submit
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </Fragment>
  );

}

export default MasterCopyDialog;

