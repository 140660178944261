import React, {Fragment, useEffect, useRef, useState} from "react";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Stack,
  Typography
} from "@mui/material";
import {CheckBoxOutlineBlank, ChevronRight, NotificationsOutlined, PrintOutlined} from "@mui/icons-material";
import {useSelector} from "react-redux";
import {playbooksActions, RootState} from "../../store/storeIndex";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import {GddChartData, MonthlyNutrient, NutrientChartData, PlaybooksResponse} from "../../types/task/PlaybooksTypes";
import {MenuMultiSelectProps} from "../../types/generic/MaterialUISelect";
import {currencyFormat, formatDate} from "../../service/generic/DateHelper";
import LoadingSpinnerContainer from "../general/LoadingSpinnerContainer";
import {grey, teal} from "@mui/material/colors";
import {taskManagementService} from "../../service/taskManagementService";
import {determineIfTokenError} from "../../store/account/authSlice";
import {useAppThunkDispatch} from "../../store/storeHooks";
import CoverageDialog from "../Dialogs/CoverageDialog";
import {getAccountParentFromAccessCode} from "../../service/account/accountHelper";
import Moment from "react-moment";
import {HtmlTooltip} from "../general/HtmlTooltip";
import {
  DARK_NAVY,
  GHOST_WHITE,
  GREEN, LIGHT_GREEN,
  LIGHT_GREY_BLUE, LIGHT_NAVY, LIGHTER_GREY_BLUE,
  LIME_GREEN,
  SIDENAV_GREY
} from "../../service/generic/ColorScheme";
import {fontSize} from "html2canvas/dist/types/css/property-descriptors/font-size";
import LeslieButton from "../general/LeslieButton";
import {playbooksResponseInit} from "../../types/task/PlaybooksInits";

type Props = {
  setShowPlaybooksLoading: (show:boolean) => void,
  showPlaybooksLoading:boolean,
}


const PlaybooksCoverageWidget: React.FC<Props> = (props) => {
  const nutrientSelectRef = useRef<HTMLDivElement>();
  let dispatch = useAppThunkDispatch();

  const gddSinceApps = useSelector((state: RootState) => state.playbooks.gddSinceApps);
  const selectedAccessCodes = useSelector((state: RootState) => state.orgInfo.allSelectedAccessCodes);
  const selectedAccounts = useSelector((state: RootState) => state.orgInfo.selectedAccounts);
  const selectedDate = useSelector((state: RootState) => state.taskInfo.selectedDate);
  const userInfo = useSelector((state: RootState) => state.userInfo.userInfo);
  const orgInfo = useSelector((state: RootState) => state.orgInfo);
  const monthlyNutrients = useSelector((state: RootState) => state.playbooks.monthlyNutrients);
  const pbAppUrls = useSelector((state: RootState) => state.playbooks.appLogPrintUrls);
  const newAlerts = useSelector((state: RootState) => state.playbooks.yearlyAndAlerts.NewAlertsBadge);
  const laborTutorial = useSelector((state: RootState) => state.experience.showLaborDashboardTutorial);

  const [areaNameSelectionGdd, setAreaNameSelectionGdd] = useState('');
  const [areaNameSelectionNutrients, setAreaNameSelectionNutrients] = useState('');
  const [selectedNutrients, setSelectedNutrients] = useState<string[]>([]);
  const [gddChartData, setGddChartData] = useState<GddChartData[]>([]);
  const [nutrientChartData, setNutrientChartData] = useState<NutrientChartData[]>([]);
  const [nutrientList, setNutrientList] = useState<string[]>([]);
  const [selectedAccessCodeIndex, setSelectedAccessCodeIndex] = useState((selectedAccounts[0] && selectedAccounts[0].accessCode === orgInfo.orgInfo.accessCode) ? 1 : 0);
  const [currentMonth, setCurrentMonth] = useState((new Date()).getMonth());

  const [openCoverageDialog, setOpenCoverageDialog] = useState(false);
  const [playbooksSelectedAccount, setPlaybooksSelectedAccount] = useState(selectedAccounts[(selectedAccounts[0] && selectedAccounts[0].accessCode === orgInfo.orgInfo.accessCode) ? 1 : 0]);

  useEffect(() => {
    if (selectedAccounts && selectedAccounts[selectedAccessCodeIndex]) {
      dispatch(playbooksActions.setSelectedApplantAccessCode(
          {value: selectedAccounts[selectedAccessCodeIndex].accessCode}));
    }
    getAvailableAppLogs();
  }, [selectedAccounts]);

  useEffect(() => {
    if (gddSinceApps.length > 0) {
      structureGddChartData();
    }
  }, [gddSinceApps, areaNameSelectionGdd]);

  useEffect(() => {
    if (monthlyNutrients.length > 0) {
      structureNutrientChartData();
    }
  }, [monthlyNutrients]);

  const structureNutrientChartData = () => {
    if (selectedNutrients.length === 0 && monthlyNutrients.length > 0) {
      let keys = Object.keys(monthlyNutrients[0].Total).filter(nut => nut.length < 3);
      setNutrientList(keys);
      selectedNutrients.push('N');
      selectedNutrients.push('P');
      selectedNutrients.push('K');
      selectedNutrients.push('Fe');
      selectedNutrients.push('Mn');
      nutrientSelectRef.current?.click();
      nutrientSelectRef.current?.focus();
    }
    if (areaNameSelectionNutrients.length === 0 && monthlyNutrients.length > 0) {
      let nutrientSelectedArea = monthlyNutrients[0].MonthlyData[0].AreaName;
      setAreaNameSelectionNutrients(nutrientSelectedArea);
      populateNutrientChartWithDataBySelected(nutrientSelectedArea);
    }
  }

  const populateNutrientChartWithDataBySelected = (nutrientSelectedArea:string) => {
    if (monthlyNutrients[0] && monthlyNutrients[0].MonthlyData[0].AreaName.length > 0) {
      let chartData: NutrientChartData[] = [];
      for (let month of monthlyNutrients) {
        let monthData = month.MonthlyData.filter(
            (data: MonthlyNutrient) => {
              return data.AreaName === nutrientSelectedArea;
            })[0];
        if (monthData) {
          console.log('set ingredients2')
          let ingredients = monthData.Ingredients;
          chartData.push({
            tag: month.Month + '',
            B: ingredients.B.YTD,
            Ca: ingredients.Ca.YTD,
            Cu: ingredients.Cu.YTD,
            Fe: ingredients.Fe.YTD,
            K: ingredients.K.YTD,
            Mg: ingredients.Mg.YTD,
            Mn: ingredients.Mn.YTD,
            Mo: ingredients.Mo.YTD,
            N: ingredients.N.YTD,
            P: ingredients.P.YTD,
            S: ingredients.S.YTD,
            Si: ingredients.Si.YTD,
            Zn: ingredients.Zn.YTD
          });
        }
      }
      setNutrientChartData(chartData);
    }
  }

  const structureGddChartData = () => {
    let gddAreaName: string = areaNameSelectionGdd;
    if (areaNameSelectionGdd.length === 0 && gddSinceApps.length > 0) {
      gddAreaName = gddSinceApps[0].AreaName;
      setAreaNameSelectionGdd(gddAreaName);
    }
    if (gddAreaName && gddAreaName.length > 0) {
      let matchedGdd = gddSinceApps.filter(gdd => gdd.AreaName === gddAreaName);
      if (matchedGdd && matchedGdd.length > 0) {
        let areaData = matchedGdd[0].GDDSinceApp;
        let chartData: GddChartData[] = [];
        chartData.push({tag: "0C", gdd: areaData.GDD0C_SinceApp.SinceApp});
        chartData.push({tag: "10C", gdd: areaData.GDD10C_SinceApp.SinceApp});
        chartData.push({tag: "32", gdd: areaData.GDD32_SinceApp.SinceApp});
        chartData.push({tag: "50", gdd: areaData.GDD50_SinceApp.SinceApp});
        setGddChartData(chartData);
      }
    }
  }

  const resetApplantWidgetData = () => {
    dispatch(playbooksActions.setAllData({playbooksResponse: playbooksResponseInit}));
    setSelectedNutrients([]);
    setAreaNameSelectionNutrients('');
    setAreaNameSelectionGdd('')
    setGddChartData([]);
    setNutrientChartData([]);
    setNutrientList([]);
  }

  const getAvailableAppLogs = (index?:number) => {
    let fetchIndex = (index !== undefined ? index : selectedAccessCodeIndex);
    if (selectedAccessCodes![fetchIndex] === orgInfo.orgInfo.accessCode) {
      fetchIndex++;
    }

    if (selectedAccounts[fetchIndex] && selectedAccounts[fetchIndex].playbooksId &&
        selectedAccounts[fetchIndex].playbooksId.length > 0) {
      resetApplantWidgetData();
      props.setShowPlaybooksLoading(true);
      let accessCodeForPlaybooksCall = (selectedAccessCodes!.length > 0) ? selectedAccessCodes![fetchIndex] : userInfo.accessCode;
      console.log(accessCodeForPlaybooksCall)
      setPlaybooksSelectedAccount(getAccountParentFromAccessCode(accessCodeForPlaybooksCall, orgInfo.orgInfo, orgInfo.orgAccounts));

      taskManagementService.getAvailablePlayBooksApplications(formatDate(selectedDate), accessCodeForPlaybooksCall, false)
          .then((pbResp: PlaybooksResponse) => {
            dispatch(playbooksActions.setAllData({playbooksResponse: pbResp}));
            //callPlaybooksDataToRefreshCache(accessCodeForPlaybooksCall);
          })
          .catch(e => {
            dispatch(determineIfTokenError(e));
          }).finally(() => props.setShowPlaybooksLoading(false));
    }
  }

  /*const callPlaybooksDataToRefreshCache = (accessCode:string) => {
    console.log(accessCode)
    taskManagementService.getAvailablePlayBooksApplications(formatDate(selectedDate), accessCode, false)
        .then((pbResp: PlaybooksResponse) => {
          resetApplantWidgetData();
          dispatch(playbooksActions.setAllData({playbooksResponse: pbResp}));
        })
        .catch(e => dispatch(determineIfTokenError(e)));
  }*/

  const onNutrientsChange = (event: SelectChangeEvent<string[]>) => {
    const {target: {value}} = event;
    let names = typeof value === 'string' ? value.split(',') : value;
    setSelectedNutrients([...names]);
  }

  const handleNextAccountSelected = () => {
    let nextIndex = (selectedAccessCodeIndex === selectedAccessCodes!.length - 1) ?
        (selectedAccounts[0].accessCode === orgInfo.orgInfo.accessCode ? 1 : 0) : selectedAccessCodeIndex + 1;
    setSelectedAccessCodeIndex(nextIndex);
    dispatch(playbooksActions.setSelectedApplantAccessCode({value:selectedAccounts[nextIndex].accessCode}));
    getAvailableAppLogs(nextIndex);
  }

  const openPBCoverageDialog = () => {
    setOpenCoverageDialog(true);
  }

  return (
      <Card sx={{width:'100%', backgroundColor:GHOST_WHITE}}>
        <CardHeader
            sx={{pb:0}}
            title={
              <Fragment>
                <Stack direction={'row'} spacing={0}>
                  {laborTutorial &&
                      <Avatar variant="rounded" sx={{bgcolor: teal["A400"], color: 'black'}}>
                        <b>C</b>
                      </Avatar>
                  }
                  <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                    <img src={`${process.env.REACT_APP_RESOURCES_URL_BASE}/images/applant-dark.png`}
                         style={{width:'170px', height: '60px'}}
                         alt={'Logo Image'}
                    />
                  </Typography>
                </Stack>
              </Fragment>
            }
            action = {
              <Fragment>
                <Stack direction={"row"} spacing={2} sx={{display: 'flex', justifyContent: 'center'}}>
                  {playbooksSelectedAccount && playbooksSelectedAccount.playbooksId &&
                  <HtmlTooltip title={
                    <Fragment>
                      <em>Application Logs</em>
                    </Fragment>
                  }>
                    <IconButton
                        size="large"
                        color="inherit"
                        onClick={() => openPBCoverageDialog()}
                    >
                      <Badge badgeContent={(pbAppUrls && pbAppUrls.length >= 0) ? pbAppUrls.length : 0}
                             color="error"
                             invisible={false} max={99}>
                        <PrintOutlined fontSize={"large"} color="action"/>
                      </Badge>
                    </IconButton>
                  </HtmlTooltip>
                  }
                  {playbooksSelectedAccount && playbooksSelectedAccount.alertsPrm &&
                  <HtmlTooltip title={
                    <Fragment>
                      <em>Alerts</em>
                    </Fragment>
                  }>
                    <IconButton
                        size="large"
                        onClick={() => window.open("http://www.covsys.net/Premium/Mobile/Notifications?PRM=" +
                            playbooksSelectedAccount.alertsPrm, "_blank")}
                        color="inherit"
                    >
                      <Badge badgeContent={(newAlerts.Number && newAlerts.Number >= 0) ? newAlerts.Number : 0}
                             color="error"
                             invisible={false} max={99}>
                        <NotificationsOutlined fontSize={"large"} color="action"/>
                      </Badge>
                    </IconButton>
                  </HtmlTooltip>
                  }
                </Stack>
              </Fragment>
            }
        />
        <CardContent sx={{pt:0}}>
          <Fragment>
            { selectedAccounts && selectedAccounts.length > 0 && selectedAccounts[selectedAccessCodeIndex] &&
            <Stack direction={'row'} spacing={2} sx={{display:'flex', justifyContent:'left', mb:2}}>
              <div>
                <Typography variant="h6" component="div" sx={{color:DARK_NAVY, mt:0.5, marginLeft:'20px'}}>
                  {selectedAccounts[selectedAccessCodeIndex].name}
                </Typography>
              </div>
              { selectedAccounts.length > 1 &&
              <div>
                { !props.showPlaybooksLoading &&
                  <IconButton onClick={handleNextAccountSelected}>
                    <ChevronRight sx={{color: DARK_NAVY}}/>
                  </IconButton>
                }
              </div>
              }
            </Stack>
            }
          </Fragment>

          <LoadingSpinnerContainer showLoading={props.showPlaybooksLoading} />

          { !props.showPlaybooksLoading && playbooksSelectedAccount &&
            <Fragment>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={6}>
                  {playbooksSelectedAccount.playbooksId &&
                    <LeslieButton text={'New App Log'}
                      handleSubmit={() => window.open("http://www.covsys.net/Premium/ApplicationLog/AddApplicationLog?courseid=" +
                          playbooksSelectedAccount.playbooksId, "_blank")}
                      sx={{mt: 1}}
                      textColor={'white'}
                      color={LIGHT_GREY_BLUE}
                      width={'100%'}
                    />
                  }
                </Grid>

                { playbooksSelectedAccount.dashPrm &&
                  <Grid item xs={12} sm={12} md={6}>
                    <LeslieButton text={'Dashboard'}
                        handleSubmit={() => window.open("http://www.covsys.net/Premium/Mobile/Dashboard?PRM=" +
                            playbooksSelectedAccount.dashPrm, "_blank")}
                        sx={{mt: 1}}
                        textColor={'white'}
                        color={LIGHT_GREY_BLUE}
                        width={'100%'}
                    />
                  </Grid>
                }
              </Grid>

              {monthlyNutrients && monthlyNutrients[currentMonth] && monthlyNutrients[currentMonth].ApplicationTotalCost &&
                <Fragment>
                  <Divider sx={{my: 2}}>
                    <Typography variant="h5" component="div" style={{color:DARK_NAVY}}>
                      Chem/Fert Cost
                    </Typography>
                  </Divider>

                  <Stack direction={'row'} justifyContent="center" spacing={3}
                         divider={<Divider orientation={'vertical'} flexItem/>}>
                    <Typography variant="h6" component="div" style={{textAlign: 'right'}}>
                      MTD
                      <div style={{color: GREEN, fontSize:'140%', fontWeight:"bold"}}>
                        {currencyFormat(monthlyNutrients[currentMonth].ApplicationTotalCost.MonthlyTotal)}
                      </div>
                    </Typography>
                    <Typography variant="h6" component="div">
                      YTD
                      <div style={{color: GREEN, fontSize:'140%', fontWeight:"bold"}}>
                        {currencyFormat(monthlyNutrients[currentMonth].ApplicationTotalCost.YTD)}
                      </div>
                    </Typography>
                  </Stack>
                </Fragment>
              }

              { nutrientChartData.length > 0 &&
                <Fragment>
                  <Divider sx={{mt: 2, mb: 2}}>
                    <Typography variant="h5" component="div" style={{color:DARK_NAVY}}>
                      Nutrients
                    </Typography>
                  </Divider>

                  <Grid container spacing={2}>
                    <Grid item sm={12} md={6}>
                      <FormControl fullWidth sx={{mb: 2}}>
                        <InputLabel id="areaNut">Select Area</InputLabel>
                        <Select
                            labelId="areaNut"
                            id="areaNut"
                            value={areaNameSelectionNutrients}
                            label="Select Area"
                            onChange={(e: SelectChangeEvent) => {
                              setAreaNameSelectionNutrients(e.target.value as string);
                              populateNutrientChartWithDataBySelected(e.target.value as string);
                            }}
                        >
                          {/*<MenuItem value={"all"}>Select All</MenuItem>*/}
                          {monthlyNutrients.length > 0 && monthlyNutrients[0].MonthlyData.map(data => (
                              <MenuItem value={data.AreaName}>{data.AreaName}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <FormControl fullWidth sx={{mb: 2}}>
                        <InputLabel id="nut">Select Nutrients</InputLabel>
                        <Select
                            ref={nutrientSelectRef}
                            labelId="nut"
                            id="nut"
                            multiple={true}
                            input={<OutlinedInput id="select-multiple-chip" label="Select Nutrients"/>}
                            value={selectedNutrients}
                            renderValue={(selected) => (
                                <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                                  {selected.map((value, index) => (
                                      <p key={value}
                                         style={{margin: 0}}>{value + ((index !== selected.length - 1) ? ', ' : ' ')}</p>
                                  ))}
                                </Box>
                            )}
                            onChange={onNutrientsChange}
                            MenuProps={MenuMultiSelectProps}
                        >
                          {nutrientList.map(key => (
                              <MenuItem
                                  key={key}
                                  value={key}
                              >
                                <Checkbox checked={selectedNutrients.indexOf(key) > -1}/>
                                <ListItemText primary={key}/>
                              </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <ResponsiveContainer width="100%" height={250}>
                    <LineChart width={730} height={250} data={nutrientChartData}
                               margin={{top: 5, right: 30, left: 20, bottom: 5}}>
                      <CartesianGrid strokeDasharray="3 3"/>
                      <XAxis dataKey="tag"/>
                      <YAxis/>
                      <Tooltip/>
                      <Legend verticalAlign="top" iconSize={25} height={30}/>

                      {selectedNutrients.includes('B') && <Line type="linear" dataKey="B" stroke={"#ff8a8a"}/>}
                      {selectedNutrients.includes('Ca') && <Line type="monotone" dataKey="Ca" stroke={"#ffbb8a"}/>}
                      {selectedNutrients.includes('Cu') && <Line type="monotone" dataKey="Cu" stroke={"#ffe48a"}/>}
                      {selectedNutrients.includes('Fe') && <Line type="monotone" dataKey="Fe" stroke={LIGHT_GREY_BLUE}/>}
                      {selectedNutrients.includes('K') && <Line type="monotone" dataKey="K" stroke={GREEN}/>}
                      {selectedNutrients.includes('Mg') && <Line type="monotone" dataKey="Mg" stroke={"#7af5dc"}/>}
                      {selectedNutrients.includes('Mn') && <Line type="monotone" dataKey="Mn" stroke={SIDENAV_GREY}/>}
                      {selectedNutrients.includes('Mo') && <Line type="monotone" dataKey="Mo" stroke={"#7abaf5"}/>}
                      {selectedNutrients.includes('N') && <Line type="monotone" dataKey="N" stroke={LIME_GREEN}/>}
                      {selectedNutrients.includes('P') && <Line type="monotone" dataKey="P" stroke={DARK_NAVY}/>}
                      {selectedNutrients.includes('S') && <Line type="monotone" dataKey="S" stroke={LIGHTER_GREY_BLUE}/>}
                      {selectedNutrients.includes('Si') && <Line type="monotone" dataKey="Si" stroke={LIGHT_NAVY}/>}
                      {selectedNutrients.includes('Zn') && <Line type="monotone" dataKey="Zn" stroke={LIGHT_GREEN}/>}
                    </LineChart>
                  </ResponsiveContainer>
                </Fragment>
              }

              { gddChartData.length > 0 &&
                <Fragment>
                  <Divider sx={{mt: 2, mb: 2}}>
                    <Typography variant="h5" component="div" style={{color:DARK_NAVY}}>
                      GDD Since Last App
                    </Typography>
                  </Divider>

                  <FormControl fullWidth sx={{mb: 2}}>
                    <InputLabel id="area">Select Area</InputLabel>
                    <Select
                        labelId="area"
                        id="area"
                        value={areaNameSelectionGdd}
                        label="Select Area"
                        onChange={(e: SelectChangeEvent) => setAreaNameSelectionGdd(e.target.value as string)}
                    >
                      {/*<MenuItem value={"all"}>Select All</MenuItem>*/}
                      {gddSinceApps.map(gdd => (
                          <MenuItem value={gdd.AreaName}>
                            <Stack direction={"row"} spacing={1} divider={<Divider orientation={'vertical'} flexItem />} >
                              <div>{gdd.AreaName}</div>
                              <div><Moment format={"ddd MM/DD/YYYY @ hh:mm a"}>{gdd.ApplicationLogDate}</Moment></div>
                            </Stack>
                          </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <Box sx={{mb: 2}}>
                    <ResponsiveContainer width="100%" height={250}>
                      <BarChart
                          width={500}
                          height={300}
                          data={gddChartData}
                          margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                          }}
                      >
                        <CartesianGrid strokeDasharray="3 3"/>
                        <XAxis dataKey="tag">
                          {/*<Label value={areaNameSelectionGdd} offset={0} position="insideBottom" />*/}
                        </XAxis>
                        <YAxis/>
                        <Tooltip/>
                        <Legend verticalAlign="top" height={30} iconType={'circle'}/>
                        <Bar dataKey={"gdd"} fill={LIGHT_GREEN} />
                      </BarChart>
                    </ResponsiveContainer>
                  </Box>
                </Fragment>
              }

              <Grid container spacing={3}>
                { playbooksSelectedAccount.reportsPrm &&
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <LeslieButton text={'Reports'}
                      handleSubmit={() => window.open("https://www.covsys.net/Premium/Mobile/ReportCategory?PRM=" +
                          playbooksSelectedAccount.reportsPrm, "_blank")}
                      sx={{mt: 1}}
                      textColor={'white'}
                      color={LIGHT_GREY_BLUE}
                      width={'100%'}
                    />
                  </Grid>
                }
                {playbooksSelectedAccount.labelSdsPrm &&
                <Grid item xs={12} sm={12} md={6} lg={4}>
                  <LeslieButton text={'Label/SDS'}
                    handleSubmit={() => window.open("https://www.covsys.net/Premium/Mobile/Label?PRM=" +
                        playbooksSelectedAccount.labelSdsPrm, "_blank")}
                    sx={{mt: 1}}
                    textColor={'white'}
                    color={LIGHT_GREY_BLUE}
                    width={'100%'}
                  />
                </Grid>
                }
                {playbooksSelectedAccount.inventoryPrm &&
                <Grid item xs={12} sm={12} md={12} lg={4}>
                  <LeslieButton text={'Inventory'}
                    handleSubmit={() => window.open("https://www.covsys.net/Premium/Mobile/Inventory?PRM=" +
                        playbooksSelectedAccount.inventoryPrm, "_blank")}
                    sx={{mt: 1}}
                    textColor={'white'}
                    color={LIGHT_GREY_BLUE}
                    width={'100%'}
                  />
                </Grid>
                }
              </Grid>
            </Fragment>
          }
        </CardContent>

        <CoverageDialog
            isOpen={openCoverageDialog} setIsOpen={setOpenCoverageDialog}
            selectedDate={selectedDate}/>

      </Card>
  );
}

export default PlaybooksCoverageWidget;