import {Box, Card, CardHeader, Chip, Divider, Stack, useMediaQuery} from "@mui/material";
import React, {Fragment} from "react";
import Typography from "@mui/material/Typography";
import moment from "moment";
import TaskActionButtonsStack from "./TaskActionsButtonsStack";
import {Priority, TaskInfo} from "../../types/task/TaskTypes";
import {ArrowUpward, CheckCircleOutlined, KeyboardArrowDown, KeyboardArrowUp, Timer} from "@mui/icons-material";
import Moment from "react-moment";
import {yellow} from "@mui/material/colors";
import {useSelector} from "react-redux";
import {RootState} from "../../store/storeIndex";
import {DARK_NAVY, GHOST_WHITE, GREEN, LIME_GREEN} from "../../service/generic/ColorScheme";

type Props = {
  task:TaskInfo,
  handleTaskSelected:(task:TaskInfo) => void,
  taskDialogOpen:boolean,
  setTaskDialogOpen:(value:boolean) => void,
}

const TaskDisplayCard:React.FC<Props> = (props) => {
  const showDateHorizontal = useMediaQuery('(min-width:1500px)');
  const isWindowXL = useMediaQuery('(min-width:1100px)');

  const orgInfo = useSelector((state: RootState) => state.orgInfo);
  const allSelectedAccessCodes = useSelector((state: RootState) => state.orgInfo.allSelectedAccessCodes);

  const getAccountNameFromAccessCode = (accessCode:string) => {
    let accountArray = orgInfo.selectedAccounts.filter(account => account.accessCode === accessCode);
    return accountArray.length > 0 ? accountArray[0].name : '';
  }

  const setTaskPriorityColor = (task:TaskInfo) => {
    let color = '';
    if(task.priority === Priority.MID){
      color = GREEN;
    }
    else if(task.priority === Priority.HIGH){
      color = DARK_NAVY;
    }
    else{
      color = LIME_GREEN;
    }
    return color;
  }

  return (
      <Card sx={{mb: 1, backgroundColor: GHOST_WHITE, py:0, borderRight: "20px solid", borderColor: setTaskPriorityColor(props.task)}} key={props.task.assignedTaskId}>
        <CardHeader
            sx={{pb:1}}
            title={
              <Fragment>
                <Typography variant="h6" component="div"
                            onClick={() => props.handleTaskSelected(props.task)}
                            style={{color: props.task.completed ? 'forestgreen' : 'indianred', cursor: "pointer"}}>
                  { showDateHorizontal ? (
                      <Fragment>
                        {props.task.taskName}
                        <Typography variant="subtitle1" component="span"
                                    onClick={() => props.handleTaskSelected(props.task)}
                                    style={{float: "right", color: 'gray'}}>
                          <Stack direction={"row"} divider={<Divider orientation={"vertical"}/>}>
                            <div style={{marginTop:'4px'}}>{moment(props.task.scheduledDate).format("ddd MM/DD/YYYY")}</div>
                          </Stack>
                        </Typography>
                      </Fragment>
                  ) : (
                      <Stack direction={"column"}
                             divider={<Divider orientation={"vertical"}/>}>
                        <div>{props.task.taskName}</div>
                        <Typography variant="caption" component={showDateHorizontal ? "span" : "div"}
                                    onClick={() => props.handleTaskSelected(props.task)}
                                    style={{float: showDateHorizontal ? "right" : "left", color: 'gray'}}>
                          <div style={{marginTop: '0px'}}>{moment(props.task.scheduledDate).format("ddd MM/DD/YYYY")}</div>
                        </Typography>
                      </Stack>
                  )}
                </Typography>

                <Fragment>
                  {!isWindowXL &&
                  <Box sx={{display:'flex', justifyContent:'end'}}>
                      <TaskActionButtonsStack task={props.task}
                                              taskDialogOpen={props.taskDialogOpen}
                                              setTaskDialogOpen={props.setTaskDialogOpen}
                                              handleTaskSelected={props.handleTaskSelected}
                      />
                  </Box>
                  }
                </Fragment>
                {/*<hr/>*/}
              </Fragment>
            }
            action={
              <Fragment>
                {isWindowXL &&
                <TaskActionButtonsStack task={props.task}
                                        taskDialogOpen={props.taskDialogOpen}
                                        setTaskDialogOpen={props.setTaskDialogOpen}
                                        handleTaskSelected={props.handleTaskSelected}
                />
                }
              </Fragment>
            }
        />
        <Box sx={{pt:0, px:1, pb:1}}>
          <Typography variant="body1" component="div" color={"dimgrey"}
                      onClick={() => props.handleTaskSelected(props.task)}
                      style={{cursor: "pointer"}}>
            {/*<Stack direction={"row"} spacing={2} alignItems={'flex-start'} justifyContent={"flex-start"}>*/}
            <Box sx={{mb:0, pb:0, display:'flex', flexWrap:'wrap', justifyContent:'flex-start'}}>
              {allSelectedAccessCodes && allSelectedAccessCodes?.length > 1 &&
              <Box sx={{mx:1, mb:1}}>
                  <Typography variant="subtitle1" component="div">
                    {getAccountNameFromAccessCode(props.task.accessCode)}
                  </Typography>
              </Box>
              }

              {/*<Box sx={{mx:0.5, mb:1}}>
                {props.task.priority === Priority.LOW &&
                <KeyboardArrowDown sx={{color: 'blue', marginTop:'3px'}}/>
                }
                {props.task.priority === Priority.MID &&
                <KeyboardArrowUp sx={{color: 'darkgoldenrod'}}/>
                }
                {props.task.priority === Priority.HIGH &&
                <ArrowUpward sx={{color: 'darkred'}}/>
                }
              </Box>*/}

              {props.task.deadlineTimeString && props.task.deadlineTimeString.length > 0 &&
              <Box sx={{mx:0.5, mb:1}}>
                  <Chip label={<Moment format={"hh:mm a"}>{props.task.deadlineTimeString}</Moment>}
                        variant="outlined"
                        icon={<Timer sx={{color:yellow[700]}}/>}
                        style={{cursor: "pointer"}}/>
              </Box>
              }

              {props.task.appLog && props.task.appLog.Url &&
              <Box sx={{mx:0.5, mb:1}}>
                  <Chip label={"App Log"} variant="outlined" icon={<CheckCircleOutlined/>}
                        style={{cursor: "pointer"}}/>
              </Box>
              }

              {props.task.subTaskLabels && props.task.subTaskLabels.length > 0 &&
              <Box sx={{mx:0.5, mb:1}}>
                  <Chip label={"Sub Task" + ((props.task.subTaskLabels.length > 1) ? "s" : "")}
                        variant="outlined"
                        style={{cursor: "pointer"}}
                        icon={<div>
                          <Box sx={{mx: 1}}><b>{props.task.subTaskLabels.length}</b></Box>
                        </div>}
                  />
              </Box>
              }

              {props.task.taskNotes.length > 0 &&
              <Box sx={{mx:0.5, mb:1}}>
                  <Chip label={"Notes"} variant="outlined" icon={<CheckCircleOutlined/>}
                        style={{cursor: "pointer"}}/>
              </Box>
              }

              {props.task.commentsList && props.task.commentsList.length > 0 &&
              <Box sx={{mx:0.5, mb:1}}>
                  <Chip label={"Comment" + ((props.task.commentsList.length > 1) ? "s" : "")}
                        variant="outlined"
                        style={{cursor: "pointer"}}
                        icon={<div>
                          <Box sx={{mx: 1}}><b>{props.task.commentsList.length}</b></Box>
                        </div>}
                  />
              </Box>
              }

              {props.task.assignedUserIds && props.task.assignedUserIds.length > 0 &&
              <Box sx={{mx:0.5, mb:1}}>
                  <Chip label={"Worker" + ((props.task.assignedUserIds.length > 1) ? "s" : "")}
                        variant="outlined"
                        style={{cursor: "pointer"}}
                        icon={<div>
                          <Box sx={{mx: 1}}><b>{props.task.assignedUserIds.length}</b></Box>
                        </div>}
                  />
              </Box>
              }
            </Box>
          </Typography>
        </Box>
      </Card>
  );
}

export default TaskDisplayCard;