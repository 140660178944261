import React, {Fragment, useEffect, useState} from "react";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Tooltip
} from "@mui/material";
import CustomTextField from "../general/CustomTextField";
import {MenuMultiSelectProps} from "../../types/generic/MaterialUISelect";
import {UserInfo} from "../../types/account/AccountTypes";
import {DatePicker, LoadingButton, LocalizationProvider, TimePicker} from "@mui/lab";
import {
  EmployeeListResponse,
  Priority, scheduleInfoValidInit,
  TaskCategories,
  TaskInfo,
  TaskInfoValid,
  TaskLocations,
  TaskResponse
} from "../../types/task/TaskTypes";
import {
  Add,
  ArrowUpward,
  CancelOutlined,
  CheckCircleOutlined,
  Edit,
  FindInPage,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Save,
  SaveOutlined
} from "@mui/icons-material";
import {
  employeeListResponseInit,
  LocationIdsUtilInfoInit,
  subTaskCheckboxInit,
  taskInfoInit
} from "../../store/task/taskInfoSlice";
import {SelectChangeEvent} from "@mui/material/Select";
import {taskManagementService} from "../../service/taskManagementService";
import {formatDate, formatDateOnEditableField, formatDateTime} from "../../service/generic/DateHelper";
import {accountActions, alertActions, playbooksActions, RootState, taskInfoActions} from "../../store/storeIndex";
import {useSelector} from "react-redux";
import {AppLogPrintUrl, PlaybooksResponse} from "../../types/task/PlaybooksTypes";
import {determineIfTokenError} from "../../store/account/authSlice";
import {useAppThunkDispatch} from "../../store/storeHooks";
import {amber, green, grey} from "@mui/material/colors";
import {DARK_NAVY, GREEN, LIME_GREEN} from "../../service/generic/ColorScheme";
import {displayAlert} from "../../store/alertSlice";
import ScheduleDialog from "./ScheduleDialog";

type Props = {
  setShowPlaybooksLoading: (show:boolean) => void,
  showPlaybooksLoading:boolean,
  tabSelected:string,
  isCreate:boolean,
  openModal: boolean,
  setOpenModal: (value:boolean) => void,
  wipeFormDataOnLoad?: boolean,
  taskToEdit?:TaskInfo,
}

const TaskForm:React.FC<Props> = (props) => {
  let dispatch = useAppThunkDispatch();

  const orgMembers = useSelector((state: RootState) => state.account.orgMembers);
  const selectedDate = useSelector((state: RootState) => state.taskInfo.selectedDate);
  const userInfo = useSelector((state: RootState) => state.userInfo.userInfo);
  const orgInfo = useSelector((state: RootState) => state.orgInfo);
  const pbAppUrls = useSelector((state: RootState) => state.playbooks.appLogPrintUrls);
  const taskInfo = useSelector((state: RootState) => state.taskInfo.taskInfo);
  const availableWorkersToAssignOnSelectedDay = useSelector((state: RootState) => state.taskInfo.availableWorkersToAssignOnSelectedDay);
  const taskCategoriesForAccountGlobal = useSelector((state: RootState) => state.taskInfo.allTaskCategoriesForAccount);
  const taskLocationsForAccountGlobal = useSelector((state: RootState) => state.taskInfo.allTaskLocationsForAccount);
  const scheduleDayObjectResponse = useSelector((state: RootState) => state.schedule.scheduleDayObjectResponse);
  const [employeeListState, setEmployeeListState] = useState<EmployeeListResponse>(employeeListResponseInit);
  const [scheduleInfoStateValid, setScheduleInfoStateValid] = useState(scheduleInfoValidInit);

  const [taskInfoState, setTaskInfoState] = useState<TaskInfo>(props.taskToEdit ? props.taskToEdit : taskInfo);
  const [taskFormTouched, setTaskFormTouched] = useState<boolean>(false);
  const [taskFormValid, setTaskFormValid] = useState({...TaskInfoValid});
  const [showLoading, setShowLoading] = useState(false);
  const [taskCreatedSuccessful, setTaskCreatedSuccessful] = useState(false);
  const [selectedDateLocal, setSelectedDateLocal] = useState(selectedDate);
  const [deadlineTimeVal, setDeadlineTimeVal] = useState<string | Date | null>(null);
  const [localSelectedOrgMembers, setLocalSelectedOrgMembers] = useState<UserInfo[] | null>(orgMembers);
  const [taskCategoriesForAccount, setTaskCategoriesForAccount] = useState(taskCategoriesForAccountGlobal);
  const [taskLocationsForAccount, setTaskLocationsForAccount] = useState(taskLocationsForAccountGlobal);
  const [appLogsLocal, setAppLogsLocal] = useState(pbAppUrls);
  const [openScheduleDialog, setOpenScheduleDialog] = React.useState(false);
  const [formInitialized, setFormInitialized] = useState(false)

  const [locationIds, setLocationIds] = useState(LocationIdsUtilInfoInit);
  const [datePickerDisabled, setDatePickerDisabled] = useState(true);

  useEffect(() => {
    if (props.wipeFormDataOnLoad) {
      clearTaskForm();
      clearTaskFormValidState();
    }
  }, [props.wipeFormDataOnLoad])

  useEffect(() => {
    const ac = new AbortController();
    if (props.openModal) {
      let newTaskState = {...taskInfo};
      newTaskState.scheduledDate = selectedDate;
      if (props.taskToEdit) {
        newTaskState = {...props.taskToEdit};
        makeTaskFormValidOnTemplateSelected(props.taskToEdit);
        populateTaskAppLogUrlIfExistsOnTask();
      }
      if (!formInitialized) {
        setTaskInfoState(newTaskState);
      }
      setFormInitialized(true)

      getAllTaskCategoriesForAccount();
      getAllTaskLocationsForAccount();
      getAllMembersForSelectedAccounts(ac);
      setSelectedDateLocal(selectedDate);
      getAvailableAppLogs();
      getRetrieveMembersResponse();
    }
    return () => {ac.abort()}
  }, [props.openModal, scheduleDayObjectResponse]);

  useEffect(() => {
    if (!openScheduleDialog) {
      const ac = new AbortController();
      getAllMembersForSelectedAccounts(ac);
    }
  }, [openScheduleDialog])

  useEffect(() => {
    if (!taskFormTouched) {
      makeTaskFormValidOnTemplateSelected(taskInfo);
    }
  }, [taskInfo, taskInfoState]);

  const populateTaskAppLogUrlIfExistsOnTask = () => {
    if (taskInfoState.appLog) {
      let newTaskState = {...taskInfoState};
      newTaskState.appLogUrl = taskInfoState.appLog.Url;
      setTaskInfoState(newTaskState);
    }
  }

  const getRetrieveMembersResponse = () => {
    if (orgInfo.allSelectedAccessCodes?.length === 1) {
      taskManagementService.getEmployeeListResponse(orgInfo.allSelectedAccessCodes![0])
          .then((employeeListResp: EmployeeListResponse) => {
            dispatch(accountActions.setOrgMembers({orgMembers:employeeListResp.employeeList}))
          }).catch(e => {
        dispatch(determineIfTokenError(e));
      });
    }
  }

  const getAllTaskCategoriesForAccount = () => {
    taskManagementService.getAllTaskCategoriesForAccount(
        (props.taskToEdit ? [props.taskToEdit.accessCode] : [orgInfo.allSelectedAccessCodes![0]]))
        .then((cats: TaskCategories[]) => {
          setTaskCategoriesForAccount(cats);
          dispatch(taskInfoActions.setAllTaskCategoriesForAccount({value: cats}));
        }).catch(e => {
      dispatch(determineIfTokenError(e));
    });
  }

  const getAllTaskLocationsForAccount = () => {
    taskManagementService.getAllTaskLocationsForAccount(
        (props.taskToEdit ? [props.taskToEdit.accessCode] : [orgInfo.allSelectedAccessCodes![0]]))
        .then((locs: TaskLocations[]) => {
          setTaskLocationsForAccount(locs);
          dispatch(taskInfoActions.setAllTaskLocationsForAccount({value: locs}));
        }).catch(e => {
      dispatch(determineIfTokenError(e));
    });
  }

  const getAllMembersForSelectedAccounts = (ac:AbortController) => {
    setLocalSelectedOrgMembers(null);

    taskManagementService.retrieveAvailableMembersToAssignOnSelectedDay(formatDate(selectedDate),
        (props.taskToEdit ? [props.taskToEdit.accessCode] : [orgInfo.allSelectedAccessCodes![0]]), ac)
        .then((workers: UserInfo[]) => {
          setLocalSelectedOrgMembers(workers);
          //dispatch(taskInfoActions.setAvailableWorkersToAssignOnSelectedDay({workers: workers}));
        }).catch(e => {
      dispatch(determineIfTokenError(e));
    });
  }

  const makeTaskFormValidOnTemplateSelected = (task:TaskInfo) => {
    if (task.taskName.trim().length > 1) {
      taskFormValid.taskName = true;
      setTaskFormValid(taskFormValid)
      setTaskFormTouched(true);
    }
    if (!isNaN(Number(task.estCompleteHours)) && String(task.estCompleteHours).trim().length > 0 &&
        Number(task.estCompleteHours) >= 0) {
      taskFormValid.estCompleteHours = true;
      setTaskFormValid(taskFormValid);
      setTaskFormTouched(true);
    }
    if (task.categoryName?.trim().length > 0 && task.categoryId?.trim().length > 0) {
      taskFormValid.category = true;
      setTaskFormValid(taskFormValid);
      setTaskFormTouched(true);
    }
    if (task.locationNames?.length > 0) {
      taskFormValid.location = true;
      setTaskFormValid(taskFormValid);
      setTaskFormTouched(true);
    }
  }

  const getAvailableAppLogs = () => {
    if (orgInfo.selectedAccounts && orgInfo.selectedAccounts.length > 0 &&
        orgInfo.selectedAccounts[0].playbooksId && orgInfo.selectedAccounts[0].playbooksId.length > 0) {
      props.setShowPlaybooksLoading(true);
      let accessCodeForPlaybooksCall = (orgInfo.allSelectedAccessCodes!.length > 0) ?
          orgInfo.allSelectedAccessCodes![0] : userInfo.accessCode;

      taskManagementService.getAvailablePlayBooksApplications(formatDate(selectedDate), accessCodeForPlaybooksCall, true)
          .then((pbResp: PlaybooksResponse) => {
            setAppLogsLocal(pbResp.appLogPrintUrls);
            dispatch(playbooksActions.setAllData({playbooksResponse: pbResp}));
            callPlaybooksDataToRefreshCache(accessCodeForPlaybooksCall);
          })
          .catch(e => {
            dispatch(determineIfTokenError(e));
          }).finally(() => props.setShowPlaybooksLoading(false));
    }
  }

  const callPlaybooksDataToRefreshCache = (accessCode:string) => {
    taskManagementService.getAvailablePlayBooksApplications(formatDate(selectedDate), accessCode, false)
        .then((pbResp: PlaybooksResponse) => {
          setAppLogsLocal(pbResp.appLogPrintUrls);
          dispatch(playbooksActions.setAllData({playbooksResponse: pbResp}));
        })
        .catch(e => dispatch(determineIfTokenError(e)));
  }

  const onChangeTaskName = (taskName:string) => {
    setTaskFormTouched(true);
    taskFormValid.taskName = taskName.trim().length > 1;
    setTaskFormValid(taskFormValid);
    let newTaskState = {...taskInfoState};
    newTaskState.taskName = taskName;
    setTaskInfoState(newTaskState);
  }

  const onChangeEstCompleteHours = (value:string) => {
    setTaskFormTouched(true);
    taskFormValid.estCompleteHours = value.trim().length > 0 && Number(value) >= 0;
    setTaskFormValid(taskFormValid);
    let newTaskState = {...taskInfoState};
    newTaskState.estCompleteHours = value;
    setTaskInfoState(newTaskState);
  }

  const onChangeTaskNotes = (taskNotes:string) => {
    setTaskFormTouched(true);
    taskFormValid.taskNotes = taskNotes.trim().length > 1;
    setTaskFormValid(taskFormValid);
    let newTaskState = {...taskInfoState};
    newTaskState.taskNotes = taskNotes;
    setTaskInfoState(newTaskState);
  }

  const onSaveTaskChange = () => {
    setTaskFormTouched(true);
    let newTaskState = {...taskInfoState};
    newTaskState.saveNewTaskTemplate = !newTaskState.saveNewTaskTemplate;
    if (newTaskState.saveNewTaskTemplate) {
      newTaskState.overwriteSavedTaskTemplate = false;
    }
    setTaskInfoState(newTaskState);
  }

  const onOverwriteSaveTaskChange = () => {
    setTaskFormTouched(true);
    let newTaskState = {...taskInfoState};
    newTaskState.overwriteSavedTaskTemplate = !newTaskState.overwriteSavedTaskTemplate;
    if (newTaskState.overwriteSavedTaskTemplate) {
      newTaskState.saveNewTaskTemplate = false;
    }
    setTaskInfoState(newTaskState);
  }

  const onChangeTaskSubTask = (value:string, index:number) => {
    setTaskFormTouched(true);
    //taskFormValid.taskNotes = value.trim().length > 1;
    //setTaskFormValid(taskFormValid);
    let newTaskState = {...taskInfoState};
    let newSubTasks = [...newTaskState.subTaskLabels];
    let newLabelCheckbox = {...newSubTasks[index]}
    newLabelCheckbox.label = value;
    newSubTasks[index] = newLabelCheckbox;
    newTaskState.subTaskLabels = newSubTasks;
    setTaskInfoState(newTaskState);
  }

  const onClickAddSubTask = (index:number) => {
    let newTaskState = {...taskInfoState};
    let newSubTasks = [...newTaskState.subTaskLabels];
    newSubTasks.push(subTaskCheckboxInit);
    newTaskState.subTaskLabels = newSubTasks;
    setTaskInfoState(newTaskState);
  }

  const onChangePriority = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTaskFormTouched(true);
    let newTaskState = {...taskInfoState};
    newTaskState.priority = (event.target as HTMLInputElement).value as Priority;
    setTaskInfoState(newTaskState);
  }

  const setDeadlineTime = (date: Date | string | null) => {
    setDeadlineTimeVal(date);
    setTaskFormTouched(true);
    taskFormValid.deadlineTime = isDeadlineTimeValid(date);
    setTaskFormValid(taskFormValid);

    let newTaskState = {...taskInfoState};
    if (date) {
      let dateToSet = formatDateTime(date!.toString());
      newTaskState.deadlineTimeString = (dateToSet) ? dateToSet : '';
    } else {
      newTaskState.deadlineTimeString = '';
    }
    setTaskInfoState(newTaskState);
  }

  const isDeadlineTimeValid = (timeChanged: string | Date | null) => {
    let notPopulated = !timeChanged || (timeChanged.toString()).trim().length === 0;
    if (notPopulated) {
      return true;
    }
    try {
      let actualDate = new Date(timeChanged!);
      if (timeChanged!.toString().toLowerCase().includes('invalid')) {
        return false;
      }
    } catch (e) {
      return false;
    }
    return true;
  }

  const isDateValid = (dateChanged: string | Date | null) => {
    try {
      let actualDate = new Date(dateChanged!);
      if (actualDate.getFullYear() < 2010 || actualDate.getFullYear() > 2040) {
        return false;
      }
    } catch (e) {
      return false;
    }
    return dateChanged !== null && (dateChanged.toString()).trim().length > 0;
  }

  const onChangeTaskDate = (taskDate:Date | null) => {
    setTaskFormTouched(true);
    taskFormValid.scheduledDate = isDateValid(taskDate);
    setTaskFormValid(taskFormValid);
    let newTaskState = {...taskInfoState};
    newTaskState.scheduledDate = formatDateOnEditableField(taskDate);
    setTaskInfoState(newTaskState);
  }

  const isFieldValid = (val:string) => val && val.length > 0;

  const onChangeCategory = (id:string) => {
    setTaskFormTouched(true);
    let newTaskState = {...taskInfoState};
    newTaskState.categoryId = id;
    newTaskState.categoryName = id ? taskCategoriesForAccount.filter(cat => cat.categoryId === id)[0].categoryName : '';
    setTaskInfoState(newTaskState);
  }

  const onAssigneesChange = (event: SelectChangeEvent<typeof taskInfoState.assignedUserIds>) => {
    setTaskFormTouched(true);
    const {target: {value}} = event;
    let names = typeof value === 'string' ? value.split(',') : value;
    let newTaskState = {...taskInfoState};
    newTaskState.assignedUserDisplayNames = names;
    let userIds = names
        .map(name => {
          return localSelectedOrgMembers!.filter(member =>
              name.includes(member.firstName) && name.includes(member.lastName))[0];
        })
        .flatMap(member => member.userId);
    newTaskState.assignedUserIds = userIds;
    setTaskInfoState(newTaskState);
  }

  const onChangeLocation = (event: SelectChangeEvent<typeof locationIds.locationIds>) => {
    setTaskFormTouched(true);
    const {target: {value}} = event;
    let names = typeof value === 'string' ? value.split(',') : value;
    let newTaskState = {...taskInfoState};
    taskFormValid.location = names.length > 0;
    setTaskFormValid(taskFormValid);
    newTaskState.locationNames = names;
    let locationIdsMatch = names
        .map(name => {
          return taskLocationsForAccount!.filter(location =>
              name === location.locationName)[0];
        })
        .flatMap(location => location.locationId);
    newTaskState.locationIds = locationIdsMatch;
    setTaskInfoState(newTaskState);
  }

  const onChangeAppLog = (event: SelectChangeEvent) => {
    setTaskFormTouched(true);
    let newTaskState = {...taskInfoState};
    newTaskState.appLogUrl = event.target.value as string;
    newTaskState.appLog = pbAppUrls.filter(appLog => appLog.Url === event.target.value as string)[0];
    setTaskInfoState(newTaskState);
  }

  const clearTaskFormValidState = () => {
    setTaskFormTouched(false);
    let newTaskFormValidState = {...taskFormValid};
    newTaskFormValidState.taskName = false;
    newTaskFormValidState.taskNotes = false;
    newTaskFormValidState.estCompleteHours = false;
    newTaskFormValidState.category = false;
    newTaskFormValidState.location = false;
    setTaskFormValid(newTaskFormValidState);
  }

  const submitTaskHandler = (closeDialog:boolean) => {
    let timeout: NodeJS.Timeout;

    if (isTaskFormValid()) {
      setShowLoading(true);
      timeout = setTimeout(() => {
        if (props.isCreate) {
          submitNewTask(closeDialog);
        } else {
          updateExistingTask(closeDialog);
        }
      }, 700);
    }

    return () => {
      clearTimeout(timeout);
    };
  }

  const submitNewTask = (closeDialog:boolean) => {
    taskManagementService.submitTask({...taskInfoState}, formatDate(selectedDateLocal), orgInfo.allSelectedAccessCodes!)
        .then((tasks: TaskResponse) => {
          dispatch(taskInfoActions.setMonthlyTasks({monthlyTasks: tasks.taskList}));
          dispatch(taskInfoActions.setSavedTemplates({taskTemplates: tasks.taskTemplates}));

          setTaskCreatedSuccessful(true);
          setTimeout(() => {
            setTaskCreatedSuccessful(false);
          }, 4800);
        }).catch(e => {
          dispatch(determineIfTokenError(e));
        }).finally(() => {
          setShowLoading(false)
          if (closeDialog && props.setOpenModal) {
            props.setOpenModal(false);
          }
          dispatch(displayAlert(true,"successfully created task"));
        });
    clearTaskForm();
    clearTaskFormValidState();
  }

  const updateExistingTask = (closeDialog:boolean) => {
    taskManagementService.updateExistingTask({...taskInfoState}, formatDate(selectedDate), orgInfo.allSelectedAccessCodes!)
        .then((tasks: TaskResponse) => {
          dispatch(taskInfoActions.setMonthlyTasks({monthlyTasks: tasks.taskList}));
          dispatch(taskInfoActions.setSavedTemplates({taskTemplates: tasks.taskTemplates}));
          clearTaskForm();
          clearTaskFormValidState();
          closeModal();
        }).catch(e => {
      dispatch(determineIfTokenError(e));
    }).finally(() => {
      setShowLoading(false);
      if (closeDialog && props.setOpenModal) {
        props.setOpenModal(false);
      }
      dispatch(displayAlert(true,"successfully updated task"));
    });
  }

  const isTaskFormValid = () => {
    return taskFormValid.taskName && (taskFormValid.estCompleteHours || doesAssigneesContainContractor()) && taskFormValid.scheduledDate &&
        isFieldValid(taskInfoState?.categoryId) && taskFormValid.location && taskFormValid.deadlineTime;
  }

  const clearTaskForm = () => {
    let newTaskState = {...taskInfoInit};
    newTaskState.scheduledDate = taskInfoState.scheduledDate;
    dispatch(taskInfoActions.setTaskInfo({taskInfo:newTaskState}));
    setTaskInfoState(newTaskState);
  }

  const closeModal = () => {
    clearTaskForm();
    clearTaskFormValidState();
    dispatch(taskInfoActions.setIsModalOpen({value: false}));
    //dispatch(taskInfoActions.setShowSideMenu({value: true}));
    if (props.setOpenModal) {
      props.setOpenModal(false);
    }
  }

  /*const useStyles = makeStyles(() => ({
   root: {
     '& .MuiInputBase-root': {
       border: "none"
     },
     '& .MuiFormLabel-root': {
       color: 'black'
     }
   }
 }));
  const classes = useStyles();*/

  const doesAssigneesContainContractor = () => {
    if(localSelectedOrgMembers) {
      for (let userId of taskInfoState.assignedUserIds) {
        for (let user of localSelectedOrgMembers) {
          if (user.userId === userId && user.role === 'ORG_CONTRACT') {
            return true;
          }
        }
      }
    }
    return false;
  }

  return (
      <Fragment>
        <Box sx={{p:2}}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <FormControl component="fieldset">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={4}>
                  <Stack direction={'row'} spacing={1}>
                    <div>
                      <IconButton sx={{mt:1, backgroundColor: datePickerDisabled ? LIME_GREEN : amber[700]}}
                                  onClick={() => setDatePickerDisabled(!datePickerDisabled)}>
                        { datePickerDisabled ? <Edit/> : <CancelOutlined/> }
                      </IconButton>
                    </div>
                    <div style={{width:'100%'}}>
                      <DatePicker
                          disabled={datePickerDisabled}
                          ignoreInvalidInputs={true}

                          label="Schedule Date *"
                          value={formatDateOnEditableField(taskInfoState.scheduledDate)}
                          onChange={onChangeTaskDate}
                          renderInput={(params) => <TextField sx={{width:'100%', borderColor:LIME_GREEN}} {...params} />}
                      />
                      { !taskFormValid.scheduledDate &&
                        <small style={{color: 'red', marginTop: 0}}>Invalid date</small>
                      }
                    </div>
                  </Stack>
                </Grid>

                <Grid item xs={12} sm={12} md={8}>
                  <CustomTextField
                      valueString={taskInfoState?.taskName}
                      isValid={taskFormValid.taskName || !taskFormTouched}
                      label={"Task Name"}
                      required={true}
                      validationText={'Invalid Task Name'}
                      onEnterKeyDownSubmitHandler={() => submitTaskHandler(false)}
                      onInputUpdate={onChangeTaskName}
                  />
                </Grid>
                {/*<Grid item xs={0}>

                </Grid>*/}

                <Grid item xs={12} sm={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel id="category">Category *</InputLabel>
                    <Select
                        labelId="category"
                        id="demo-simple-select"
                        value={taskInfoState?.categoryId}
                        label="Category *"
                        onChange={(e:SelectChangeEvent) => onChangeCategory(e.target.value as string)}
                        sx={{overflow: "hidden", width: '100%', paddingY:'4px'}}
                    >
                      <MenuItem value={undefined}>None</MenuItem>
                      {taskCategoriesForAccount.map((category, index) => (
                          <MenuItem value={category.categoryId} key={'cat' + index}>
                            {category.categoryName}
                          </MenuItem>
                      ))}
                    </Select>
                    <Box sx={{ width: '100%' }}>
                      { localSelectedOrgMembers === null && <LinearProgress />}
                    </Box>
                    <small style={{color:'red'}} hidden={isFieldValid(taskInfoState?.categoryId) || !taskFormTouched}>
                      Task Category is required.
                    </small>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel id="locations">Locations *</InputLabel>
                    <Select
                        labelId="locations"
                        id="location-select"
                        multiple={true}
                        input={<OutlinedInput id="select-multiple-chip" label="locations *"/>}
                        value={taskInfoState?.locationNames}
                        renderValue={(selected) => (
                            <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                              {selected.map((value) => (
                                  <Chip key={value} label={value}/>
                              ))}
                            </Box>
                        )}
                        onChange={onChangeLocation}
                        MenuProps={MenuMultiSelectProps}
                    >
                      {taskLocationsForAccount?.map((location: TaskLocations) => (
                          <MenuItem
                              key={location.locationId}
                              value={location.locationName}
                          >
                            <Checkbox
                                checked={taskInfoState.locationNames.indexOf(location.locationName) > -1}/>
                            <ListItemText primary={location.locationName}/>
                          </MenuItem>
                      ))}
                    </Select>
                    <Box sx={{ width: '100%' }}>
                      { localSelectedOrgMembers === null && <LinearProgress />}
                    </Box>
                    <small style={{color:'red'}} hidden={taskFormValid.location || !taskFormTouched}>
                      Task Location is required.
                    </small>
                  </FormControl>
                </Grid>



                <Grid item xs={12} sm={12} md={6}>
                  <Stack direction={'row'} spacing={1}>
                    <div>
                      <IconButton sx={{mt:1, backgroundColor: LIME_GREEN }}
                                  onClick={() => setOpenScheduleDialog(true)}>
                        <AddIcon/>
                      </IconButton>
                    </div>
                    <div style={{width:'100%'}}>
                      <FormControl fullWidth>
                        <InputLabel id="assignees">Assignees</InputLabel>
                        <Select
                            labelId="assignees"
                            id="assigneesSelect"
                            multiple={true}
                            input={<OutlinedInput id="select-multiple-chip" label="assignees"/>}
                            value={taskInfoState?.assignedUserDisplayNames}
                            renderValue={(selected) => (
                                <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                                  {selected.map((value) => (
                                      <Chip key={value} label={value}/>
                                  ))}
                                </Box>
                            )}
                            onChange={onAssigneesChange}
                            MenuProps={MenuMultiSelectProps}
                        >
                          {localSelectedOrgMembers?.map((member: UserInfo) => (
                              <MenuItem
                                  key={member.userId}
                                  value={member.firstName + ' ' + member.lastName}
                              >
                                <Checkbox
                                    checked={taskInfoState.assignedUserDisplayNames.indexOf(member.firstName + ' ' + member.lastName) > -1}/>
                                <ListItemText primary={member.firstName + ' ' + member.lastName}/>
                              </MenuItem>
                          ))}
                        </Select>
                        <Box sx={{ width: '100%' }}>
                          { localSelectedOrgMembers === null && <LinearProgress />}
                        </Box>
                      </FormControl>
                    </div>
                  </Stack>
                </Grid>

                {orgInfo.orgInfo.preferenceConfig.requireTaskEstimatedCompletionTime &&
                <Grid item xs={12} md={6}>
                  <CustomTextField
                      valueString={taskInfoState?.estCompleteHours}
                      isValid={taskFormValid.estCompleteHours || !taskFormTouched || doesAssigneesContainContractor()}
                      label={"Estimated Hrs to Complete"}
                      required={true}
                      validationText={'Invalid Estimated Completion Time'}
                      onEnterKeyDownSubmitHandler={() => submitTaskHandler(false)}
                      onInputUpdate={onChangeEstCompleteHours}
                  />
                </Grid>
                }
                <Grid item sm={12} md={6}>
                  <TimePicker
                      label="Deadline Time"
                      value={taskInfoState.deadlineTimeString}
                      onChange={(newValue) => {
                        setDeadlineTime(newValue);
                      }}
                      /*className={classes.root}*/
                      inputFormat={"hh:mm a"}
                      toolbarFormat='yyyy-MM-dd'
                      renderInput={(params) =>
                          <TextField {...params} error={false}
                                     sx={{
                                       width:"100%",
                                       '& fieldset' : {
                                         borderColor: (taskFormValid.deadlineTime ||
                                            (deadlineTimeVal && deadlineTimeVal!.toString().length === 0)) ? 'grey' : 'grey'
                                       },
                                     }}
                          />
                      }
                  />
                  { !taskFormValid.deadlineTime && (deadlineTimeVal && deadlineTimeVal!.toString().length > 0) &&
                    <p style={{color: 'red', marginTop: 0}}>Invalid time value</p>
                  }
                </Grid>

                { (orgInfo.selectedAccounts[0] && orgInfo.selectedAccounts[0].playbooksId &&
                    (!props.taskToEdit || !taskInfoState?.appLog || (taskInfoState?.appLog && taskInfoState?.appLogUrl))) &&
                  <Grid item xs={12} sm={12} md={6}>
                    <FormControl fullWidth>
                      <InputLabel id="apploglabel">Application Log</InputLabel>
                      <Select
                          labelId="apploglabel"
                          id="demo-simple-select"
                          value={taskInfoState?.appLogUrl}
                          label="Application Log"
                          onChange={onChangeAppLog}
                          sx={{overflow: "hidden", width: '100%'}}
                          renderValue={(value: any) => (
                              <Stack direction={"row"}
                                     spacing={1}
                                     divider={<Divider orientation="vertical" flexItem/>}>
                                <div>{taskInfoState?.appLog?.ApplicationAreaLog}</div>
                                <div>{taskInfoState?.appLog?.ApplicationLogDate}</div>
                              </Stack>
                          )}
                      >
                        <MenuItem value={undefined}>None</MenuItem>
                        {appLogsLocal.map(((appLog: AppLogPrintUrl, index) => (
                            <MenuItem value={appLog.Url} key={'applog' + index}>
                              <Stack direction={"row"}
                                     spacing={1}
                                     divider={<Divider orientation="vertical" flexItem/>}>
                                <div>{appLog.ApplicationAreaLog}</div>
                                <div>{appLog.ApplicationLogDate}</div>
                                <Tooltip title={'View Playbooks Application Log'}>
                                  <IconButton
                                      onClick={() => {
                                        let url = appLog.Url;
                                        if (url.includes('http:\\c')) {
                                          url = url.replace('http:\\c', 'https://c');
                                        }
                                        window.open(url, "_blank")
                                      }}> {/*"https://covsys.net/Premium/ApplicationLog/PrintApplicationLog?PRM=tQHphtLCQZU="*/}
                                    <FindInPage/>
                                  </IconButton>
                                </Tooltip>
                              </Stack>
                            </MenuItem>
                        )))}
                      </Select>
                      <Box sx={{ width: '100%' }}>
                        { props.showPlaybooksLoading && <LinearProgress />}
                      </Box>
                    </FormControl>
                  </Grid>
                }
                <Grid item xs={12}>
                  <CustomTextField
                      valueString={taskInfoState?.taskNotes}
                      label={"Task Notes"}
                      multiline={4}
                      onEnterKeyDownSubmitHandler={() => submitTaskHandler(false)}
                      onInputUpdate={onChangeTaskNotes}
                  />
                </Grid>
                <Grid item xs={12} style={{textAlign:'center'}}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Assignment Order</FormLabel>
                    <RadioGroup row aria-label="gender"
                                value={taskInfoState.priority}
                                onChange={onChangePriority}
                                name="row-radio-buttons-group">
                      <FormControlLabel value={Priority.LOW} control={<Radio />}
                                        label={
                                          <Fragment>
                                            {'3rd'}
                                            <KeyboardArrowDown style={{color:LIME_GREEN}}/>
                                          </Fragment>
                                        } />
                      <FormControlLabel value={Priority.MID} control={<Radio />}
                                        label={
                                          <Fragment>
                                            {'2nd'}
                                            <KeyboardArrowUp style={{color:GREEN}}/>
                                          </Fragment>
                                        } />
                      <FormControlLabel value={Priority.HIGH} control={<Radio />}
                                        label={
                                          <Fragment>
                                            {'1st'}
                                            <ArrowUpward style={{color:DARK_NAVY}}/>
                                          </Fragment>
                                        } />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  { taskInfoState.subTaskLabels.map((labelCheck, index) => (
                      <Fragment key={'stlabel' + index}>
                        <CustomTextField
                            valueString={labelCheck.label}
                            /*isValid={taskFormValid.taskNotes || !taskFormTouched}*/
                            label={"Sub Task"}
                            sx={{width:'100%', mb:1}}
                            /*validationText={'Invalid First Name'}*/
                            onEnterKeyDownSubmitHandler={() => onClickAddSubTask(index)}
                            onInputUpdate={(value) => onChangeTaskSubTask(value, index)}
                            startAdornment={true}
                            endAdornment={taskInfoState.subTaskLabels.length - 1 === index ? true : false}
                        >
                          <div>{(index + 1) + ')'}</div>
                          <Tooltip title={'Add Subtask'}>
                            <IconButton sx={{backgroundColor:LIME_GREEN}}
                                        onClick={() => onClickAddSubTask(index)}>
                              <Add/>
                            </IconButton>
                          </Tooltip>
                        </CustomTextField>
                      </Fragment>
                  ))}
                </Grid>

                <Fragment>
                    <Grid item sm={12} md={taskInfoState.templateId ? 6 : 12}>
                        <FormGroup style={{textAlign: 'center'}}>
                            <FormControlLabel
                                control={
                                  <Checkbox
                                      checked={taskInfoState.saveNewTaskTemplate}
                                      onChange={onSaveTaskChange}
                                      icon={<SaveOutlined/>}
                                      checkedIcon={<Save/>}
                                      sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}
                                  />
                                }
                                label={taskInfoState.templateId ? "Save as new Task Template" : "Save Task as a Template"}
                            />
                        </FormGroup>
                    </Grid>
                  {taskInfoState.templateId &&
                  <Grid item sm={12} md={6}>
                      <FormGroup style={{textAlign: 'center'}}>
                          <FormControlLabel
                              control={
                                <Checkbox
                                    checked={taskInfoState.overwriteSavedTaskTemplate}
                                    onChange={onOverwriteSaveTaskChange}
                                    icon={<SaveOutlined/>}
                                    checkedIcon={<Save/>}
                                    sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}
                                />
                              }
                              label={"Overwrite Current Template"}
                          />
                      </FormGroup>
                  </Grid>
                  }
                </Fragment>
              </Grid>
            </FormControl>
          </LocalizationProvider>
        </Box>

        <Box sx={{position:'sticky', bottom:0, p:1, bgcolor:grey[400], zIndex:100}}>
          { taskCreatedSuccessful &&
            <Box sx={{float: 'left'}}>
              <CheckCircleOutlined sx={{color: green[500]}} fontSize={'large'}/>
            </Box>
          }
          <Stack direction={'row'} spacing={2} sx={{display:'flex', justifyContent:'end'}}>
            <div>
              <Tooltip title="Clear Form">
                <Button aria-label="settings"
                        style={{justifyContent: "right", color: amber["700"],
                          textShadow: '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
                        }}
                        variant={'text'}
                        onClick={() => {
                          clearTaskForm();
                          clearTaskFormValidState();
                        }}>
                  Clear
                </Button>
              </Tooltip>
            </div>
            <div>
              <Tooltip title="Cancel Editing">
                <Button aria-label="settings" style={{justifyContent: "right", color: grey["200"]}}
                        variant={'text'}
                        onClick={closeModal}>
                  Cancel
                </Button>
              </Tooltip>
            </div>
            <div>
              { props.isCreate &&
                <LoadingButton loading={showLoading}
                               loadingIndicator={<CircularProgress color={"inherit"} sx={{color: "white"}} size={16}/>}
                               type={'button'}
                               sx={{
                                 cursor: !(isTaskFormValid()) ? 'not-allowed' : 'pointer',
                                 bgcolor: isTaskFormValid() ? LIME_GREEN : grey[200],
                                 borderColor: isTaskFormValid() ? "none" : LIME_GREEN,
                                 color: DARK_NAVY
                               }}
                               variant={isTaskFormValid() ? "contained" : "outlined"}
                               onClick={() => submitTaskHandler(false)}>
                  Submit
                </LoadingButton>
              }
            </div>
            <div>
              <LoadingButton loading={showLoading}
                             loadingIndicator={<CircularProgress color={"inherit"} sx={{color: "white"}} size={16}/>}
                             type={'button'}
                             sx={{cursor: !(isTaskFormValid()) ? 'not-allowed' : 'pointer',
                                bgcolor: isTaskFormValid() ? LIME_GREEN : grey[200],
                                borderColor: isTaskFormValid() ? "none" : LIME_GREEN,
                                color: DARK_NAVY
                             }}
                             variant={isTaskFormValid() ? "contained" : "outlined"}
                             onClick={() => submitTaskHandler(true)}>
                Submit And Close
              </LoadingButton>
            </div>
          </Stack>
        </Box>

        <ScheduleDialog title={"Schedule Employee"}
                        scheduledObjects={scheduleDayObjectResponse}
                        selectedDate ={selectedDate}
                        isOpen={openScheduleDialog} setIsOpen={setOpenScheduleDialog}
                        employeeList={employeeListState}
                        scheduleInfoValid={scheduleInfoStateValid}
        />

      </Fragment>
  );
}

export default TaskForm;